import { MoveLeft } from 'lucide-react';

type GoBackProps = {
  onClick?: () => void;
}

const GoBack = ({ onClick }: GoBackProps) => {
  return (
    <div
      className="flex gap-4 pl-4 p-8 mb-8 cursor-pointer hover:bg-[#fff] rounded-lg"
      onClick={onClick}
    >
      <MoveLeft />
      <span>
        Go terug
      </span>
    </div>
  )
}

export default GoBack;
