import { z } from 'zod';

export const addCategorySchema = z.object({
  name: z.string().min(2, { message: 'Naam moet minimaal 2 tekens bevatten.' }),
  description: z.string().min(2, { message: 'Beschrijving moet minimaal 2 tekens bevatten.' }),
  parentId: z.string().uuid({ message: 'Ongeldig UUID-formaat voor ouder-ID.' }),
  imageSrc: z.string()
    .nonempty({ message: 'Kies een afbeelding voor de categorie' })
});

export type AddCategoryDto = z.infer<typeof addCategorySchema>;
