import { Edit, Plus, ShoppingCart, Trash, Users } from 'lucide-react';
import { useEffect, useState } from 'react';

import { useModalStore } from '../../stores/modalStore';
import { MODALS } from '../constants';
import { Button, Placeholder } from '../elements';
import { Loading } from '../partials';

import VideoPlayer from './_videoPlayer';
import { Tutorial } from './dto/tutorials.dto';
import { useTutorials } from './hooks';
import AddTutorialModal from './modal/AddTutorialModal';
import DeleteTutorialModal from './modal/DeleteTutorialModal';
import EditTutorialModal from './modal/EditTutorialModal';

type TutorialsProps = {
  tutorials: Tutorial[];
};

const Tutorials = ({ tutorials }: TutorialsProps) => {
  const { openModal } = useModalStore();
  const [tutorialToDelete, setTutorialToDelete] = useState<Tutorial | null>(null);
  const [tutorialToEdit, setTutorialToEdit] = useState<Tutorial | null>(null);

  const openDeleteTutorial = (tutorial: Tutorial) => {
    setTutorialToDelete(tutorial);
    openModal(MODALS.DELETE_TUTORIAL);
  }

  const openEditTutorial = (tutorial: Tutorial) => {
    setTutorialToEdit(tutorial);
    openModal(MODALS.EDIT_TUTORIAL);
  }

  if (tutorials.length === 0) {
    return (
      <Placeholder
        text="Er zijn nog geen tutorials beschikbaar"
      />
    )
  }

  return (
    <>
      <DeleteTutorialModal tutorialId={tutorialToDelete?.id} />
      <EditTutorialModal tutorial={tutorialToEdit} />
      <ul className="grid grid-cols-1 gap-5 md:grid-cols-2 xl:grid-cols-3 tablet:grid-cols-1 lg:grid-cols-2">
        {tutorials.map((tutorial) => (
          <li
            className="relative rounded-lg bg-[#fff] flex flex-col min-h-[150px] p-4"
            key={tutorial.id}
          >
            <div>
            </div>
            <div className="w-full h-[200px] bg-[#eee] rounded-2xl mb-4">
              {
                tutorial.video_url && (
                  <VideoPlayer filePath={tutorial.video_url} />
                )
              }
            </div>

            <div className="flex flex-col mb-4 pb-[60px] ">
              <div className="flex gap-2 items-center mb-4 text-xs  text-[#8c8c8c]">

                {
                  tutorial.isPublic && (
                    <>
                      <Users />
                      <span className="text-sm">Publiek toegankelijk</span>
                    </>
                  )
                }

                {
                  !tutorial.isPublic && (
                    <>
                      <ShoppingCart />
                      <span className="text-sm">Gekoppeld aan product</span>
                    </>
                  )
                }

                {
                  tutorial.isActive && (
                    <span className="text-sm">/ Actief</span>
                  )
                }

                {
                  !tutorial.isActive && (
                    <span className="text-sm">/ Inactief</span>
                  )
                }

              </div>
              <span className="text-xl block font-semibold">{tutorial.name}</span>
              <span>{tutorial.description}</span>
            </div>


            <div 
              className="flex justify-between gap-2 absolute bottom-[1rem]"
              style={{ width: 'calc(100% - 2rem)' }}
            >
              <Button
                onClick={() => openEditTutorial(tutorial)}
                icon={<Edit />}
                variant="tertiary"
                text="Aanpassen"
                fullWidth
              />

              <Button
                onClick={() => openDeleteTutorial(tutorial)}
                icon={<Trash />}
                variant="delete"
              />
            </div>
          </li>
        ))}
      </ul>
    </>
  )
}

const TutorialPage = () => {
  const { openModal } = useModalStore();
  const { data, isError, error, isLoading } = useTutorials();

  const openAddTutorial = () => {
    openModal(MODALS.ADD_TUTORIAL);
  };

  useEffect(() => {
    if (isError) {
      console.error(error)
    }
  }, [isError, error])

  return (
    <>
      <AddTutorialModal />
      <main
        className="p-8 md:p-16"
      >
        <div className="flex-col items-start flex gap-4 md:flex-row md:items-center mb-16 md:justify-between">
          <h1 className="font-bold">Tutorials</h1>
          <div className="flex gap-4">
            <Button
              onClick={openAddTutorial}
              variant="primary"
              text="voeg tutorial toe"
              icon={<Plus />}
            />
          </div>
        </div>

        {
          isLoading && <Loading />
        }

        {
          !isLoading && isError && (
            <div>
              Er ging iets mis tijdens het ophalen van de tutorials
            </div>
          )
        }

        {
          !isLoading
          && !isError
          && data
          && <Tutorials tutorials={data} />
        }
      </main>
    </>
  );
};

export default TutorialPage;
