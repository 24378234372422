import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';

import ApiError from '../ApiError';
import { QUERY_KEYS } from '../constants';
import { SortRule } from '../model/SortRule';
import { doFetch } from '../utils';

import { AddCustomerDto, addCustomerSchema } from './dto/add-customer.dto';
import { customerOrdersSchema } from './dto/customer-orders.dto';
import { CustomerDto, customerSchema } from './dto/customer.dto';
import { paginatedCustomersSchema } from './dto/paginated-customers.dto';
import {
  UpdateCustomerDto,
  updateCustomerSchema,
} from './dto/update-customer.dto';

const URLS = {
  ADD_CUSTOMER: '/api/v1/customers',
  UPDATE_CUSTOMER: (customerId: string) => `/api/v1/customers/${customerId}`,
  DELETE_CUSTOMER: (customerId: string) => `/api/v1/customers/${customerId}`,
  GET_PAGINATED_CUSTOMERS: '/api/v1/customers/paginated',
  GET_CUSTOMER_BY_ID: (customerId: string) => `/api/v1/customers/${customerId}`,
  GET_ORDERS_BY_CUSTOMER_ID: (customerId: string) =>
    `/api/v1/orders/customer/${customerId}`,
};

export const useUpdateCustomerForm = (customer: CustomerDto | undefined) => {
  return useForm<UpdateCustomerDto>({
    defaultValues: customer,
    resolver: zodResolver(updateCustomerSchema),
  });
};

export const useAddCustomerForm = (customer: AddCustomerDto) => {
  return useForm<AddCustomerDto>({
    defaultValues: customer,
    resolver: zodResolver(addCustomerSchema),
  });
};

export const useDeleteCustomer = () => {
  const queryClient = useQueryClient();
  const { isPending, isError, mutate, error } = useMutation({
    mutationFn: async (customerId: string) => {
      const { status, data } = await doFetch(
        URLS.DELETE_CUSTOMER(customerId),
        'DELETE'
      );

      if (status !== 200) {
        throw new Error(data.message);
      }
    },
  });

  const deleteCustomer = async (customerId: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      mutate(customerId, {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.customers],
          });
          resolve();
        },
        onError: reject,
      });
    });
  };

  return {
    isPending,
    isError,
    deleteCustomer,
    error,
  };
};

export const useUpdateCustomer = () => {
  const queryClient = useQueryClient();
  const { isPending, isError, mutate, error } = useMutation({
    mutationFn: async (updateCustomerDto: UpdateCustomerDto) => {
      const { id, name, email, state } = updateCustomerDto;

      const { status, data } = await doFetch(URLS.UPDATE_CUSTOMER(id), 'PUT', {
        name,
        email,
        state,
      });

      if (status !== 200) {
        throw new ApiError(data.error, status);
      }

      return data;
    },
  });

  const updateCustomer = async (
    updateCustomerDto: UpdateCustomerDto
  ): Promise<AddCustomerDto> => {
    return new Promise((resolve, reject) => {
      mutate(updateCustomerDto, {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.customers],
          });
          resolve(updateCustomerDto);
        },
        onError: reject,
      });
    });
  };

  return {
    isPending,
    isError,
    updateCustomer,
    error,
  };
};

export const useAddCustomer = () => {
  const queryClient = useQueryClient();
  const { isPending, isError, mutate, error } = useMutation({
    mutationFn: async (addCustomerDto: AddCustomerDto) => {
      const { name, email } = addCustomerDto;

      const { status, data } = await doFetch(URLS.ADD_CUSTOMER, 'POST', {
        name,
        email,
      });

      if (status !== 201) {
        throw new Error(data.message);
      }

      return data;
    },
  });

  const addCustomer = async (
    addCustomerDto: AddCustomerDto
  ): Promise<AddCustomerDto> => {
    return new Promise((resolve, reject) => {
      mutate(addCustomerDto, {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.customers],
          });
          resolve(addCustomerDto);
        },
        onError: reject,
      });
    });
  };

  return {
    isPending,
    isError,
    addCustomer,
    error,
  };
};

export const usePaginatedCustomers = (
  page: number,
  itemsPerPage: number,
  filter: string,
  sortRules: SortRule[]
) => {
  return useQuery({
    queryKey: [QUERY_KEYS.customers, page, itemsPerPage, filter, sortRules],
    queryFn: async () => {
      const { status, data } = await doFetch(
        URLS.GET_PAGINATED_CUSTOMERS,
        'POST',
        {
          page,
          itemsPerPage,
          filter,
          sortRules: sortRules.filter((rule) => rule.sortOrder !== ''),
        }
      );

      if (status !== 200) {
        throw new Error();
      }

      return paginatedCustomersSchema.parse(data);
    },
  });
};

export const useOrdersFromCustomerById = (customerId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.customers, QUERY_KEYS.orders, customerId],
    queryFn: async () => {
      const { status, data } = await doFetch(
        URLS.GET_ORDERS_BY_CUSTOMER_ID(customerId),
        'GET'
      );

      if (status !== 200) {
        throw new Error();
      }

      return customerOrdersSchema.parse(data);
    },
  });
};

export const useCustomerById = (customerId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.customers, customerId],
    queryFn: async () => {
      const { status, data } = await doFetch(
        URLS.GET_CUSTOMER_BY_ID(customerId),
        'GET'
      );

      if (status !== 200) {
        throw new Error();
      }

      return customerSchema.parse(data);
    },
  });
};
