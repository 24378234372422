type ErrorProps = {
  error: Error | null;
};

const Error = ({ error }: ErrorProps) => {
  if (error !== null) {
    console.error(error);
  }

  return (
    <main className="p-16">
      <h1>An error occurred please try again in a few minutes...</h1>
    </main>
  );
};

export default Error;
