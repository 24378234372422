import Images from './_images';
import { useRealisations } from './hooks';

const RealisationsPage = () => {
  const { data: realisations } = useRealisations();
  return (
    <>
      <main className="p-4 md:p-16 relative min-h-[calc(100vh-120px)]">
        <div className="flex gap-4 items-center mb-8 justify-between">
          <h1 className="font-bold mb-0">Realisaties</h1>
        </div>

        <Images images={realisations ?? []} />
      </main>
    </>
  );
}

export default RealisationsPage;
