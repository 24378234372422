import { useMutation, useQueryClient } from '@tanstack/react-query';

import { doFetch } from '../utils';

const URLS = {
  LOGOUT: '/api/v1/auth/logout',
};

export const useLogout = () => {
  const queryClient = useQueryClient();
  const { isPending, isError, mutate, error } = useMutation({
    mutationFn: async () => {
      const { status, data } = await doFetch(URLS.LOGOUT, 'POST');

      if (status !== 200) {
        throw new Error(data.message);
      }
    },
  });

  const logout = async (): Promise<void> => {
    return new Promise((resolve, reject) => {
      mutate(undefined, {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: [],
          });
          resolve();
        },
        onError: reject,
      });
    });
  };

  return {
    isPending,
    isError,
    logout,
    error,
  };
};
