import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { QUERY_KEYS } from '../constants';
import { doFetch, uploadFileFetch } from '../utils';

import { realisationsSchema } from './dto/realisations.dto';

const URLS = {
  GET_REALISATIONS: '/api/v1/realisations',
  UPLOAD_REALISATION_IMAGES: '/api/v1/realisations',
  DELETE_REALISATION: (realisationId: string) =>
    `/api/v1/realisations/${realisationId}`,
};

export const useRealisations = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.realisations],
    queryFn: async () => {
      const { status, data } = await doFetch(URLS.GET_REALISATIONS, 'GET');
      if (status !== 200) {
        throw new Error(data.message);
      }

      return realisationsSchema.parse(data);
    },
  });
};

export const useUploadImages = () => {
  const queryClient = useQueryClient();
  const { isPending, isError, mutate, error } = useMutation({
    mutationFn: async ({ files }: { files: File[] }) => {
      const url = URLS.UPLOAD_REALISATION_IMAGES;
      const { status } = await uploadFileFetch(url, 'POST', files);

      if (status !== 201) {
        throw new Error('An error occurred while uploading images');
      }

      return { status };
    },
  });

  const uploadImages = async (files: File[]) => {
    return new Promise((resolve, reject) => {
      mutate(
        { files },
        {
          onSuccess: (data) => {
            queryClient.invalidateQueries({
              queryKey: [QUERY_KEYS.realisations], // Assuming there's a query key for products
            });
            resolve(data);
          },
          onError: reject,
        }
      );
    });
  };

  return {
    isPending,
    isError,
    uploadImages,
    error,
  };
};

export const useDeleteRealisation = () => {
  const queryClient = useQueryClient();

  const { isPending, isError, mutate, error } = useMutation({
    mutationFn: async ({ id }: { id: string }) => {
      const { status } = await doFetch(URLS.DELETE_REALISATION(id), 'DELETE');

      if (status !== 200) {
        throw new Error('An error occurred while deleting the realisation');
      }

      return { status };
    },
  });

  const deleteRealisation = async (realisationId: string) => {
    return new Promise((resolve, reject) => {
      mutate(
        { id: realisationId },
        {
          onSuccess: (data) => {
            queryClient.invalidateQueries({
              queryKey: [QUERY_KEYS.realisations], // Assuming there's a query key for products
            });
            resolve(data);
          },
          onError: reject,
        }
      );
    });
  };

  return {
    isPending,
    isError,
    deleteRealisation,
    error,
  };
};
