import { LineChart } from '../elements/LineChart';
import { PieChart } from '../elements/PieChart';
import OrdersGrid from '../orders/OrdersGrid';

import { useDashboardStats } from './hooks';


const Dashboard = () => {
  const { data, isError, error } = useDashboardStats();

  if (isError) {
    console.error(error.message)
  }

  const amountOfOrders = [
    {
      id: 'Aantal bestellingen',
      color: '#5ee996',
      data: data?.order_stats?.map(data => {
        return {
          x: data.month,
          y: data.amount_of_orders
        }
      }) ?? []
    },
  ]

  const revenueData = [
    {
      id: 'Omzet',
      color: '#e9ad5e',
      data: data?.order_stats?.map(data => {
        return {
          x: data.month,
          y: data.revenue
        }
      }) ?? []
    }
  ]

  const userData = [
    {
      id: 'Actief',
      label: 'Actief',
      value: data?.customer_states.ACTIVE ?? 0,
      color: '#5ee996'
    },
    {
      id: 'Te activeren',
      label: 'Te activeren',
      value: data?.customer_states.TO_ACTIVATE ?? 0,
      color: '#e9ad5e'
    },
    {
      id: 'Geblokkeerd',
      label: 'Geblokkeerd',
      value: data?.customer_states.BLOCKED ?? 0,
      color: '#e95e5e'
    }
  ]

  return (
    <main className="p-4 md:p-16">
      <h1 className="font-bold mb-8">Dashboard</h1>

      {/* {isLoading && <div>Loading...</div>} */}

      <section className="flex p-8 bg-[#fff] rounded-lg flex-col gap-4 mb-4">
        <h2 className="text-lg font-semibold">Omzet</h2>

        <div className="flex md:p-8 justify-center items-center bg-primary rounded-lg">
          <div className="h-[350px] w-[100%]">
            <LineChart data={revenueData} />
          </div>
          <div className="h-[350px] w-[100%]">
            <LineChart data={amountOfOrders} />
          </div>
        </div>
      </section>

      <section className="flex p-8 bg-[#fff] rounded-lg flex-col gap-4 mb-4">
        <h2 className="text-lg font-semibold">Recente bestellingen</h2>

        <OrdersGrid
          data={data?.recent_orders ?? []}
          sortRules={[]}
        />
      </section>

      <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
        <section className="flex p-8 bg-[#fff] rounded-lg flex-col gap-4 mb-4">
          <h2 className="text-lg font-semibold">klanten</h2>

          <div className="flex justify-center items-center p-16 bg-primary rounded-lg">
            <div className="w-[400px] h-[250px]">
              <PieChart data={userData} />
            </div>
          </div>
        </section>
      </div>
    </main>
  );
};

export default Dashboard;
