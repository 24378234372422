import { X } from 'lucide-react';

import { useModalStore } from '../../../stores/modalStore';
import { useToastStore } from '../../../stores/toastStore';
import { MODALS } from '../../constants';
import InputError from '../../elements/InputError';
import Modal from '../../elements/Modal';
import { useAddAdministrator, useAddAdministratorForm } from '../hooks';

const AddAdminModal = () => {
  const modalStore = useModalStore();
  const { errorToast, successToast } = useToastStore();
  const { isPending, isError, error, addAdministrator, reset: resetError } = useAddAdministrator();

  const {
    handleSubmit,
    register,
    reset: resetForm,
    getValues,
    formState: { errors },
  } = useAddAdministratorForm({ name: '', email: '' });

  const close = () => {
    resetError();
    resetForm();
    modalStore.closeModal(MODALS.ADD_ADMIN);
  };

  const submit = async () => {
    try {
      await addAdministrator(getValues());
      successToast('Administrator toegevoegd');
      resetError();
      resetForm();
      close();
    } catch (err) {
      errorToast('Er is iets misgegaan bij het toevoegen van de administrator');
      console.log(err);
    }
  };

  return (
    <Modal id={MODALS.ADD_ADMIN}>
      <>
        <div className="relative pt-4">
          <h1 className="ml-8 text-text text-2xl font-bold">Nieuwe administrator</h1>
          <X
            className="absolute right-0 top-0 text-text cursor-pointer"
            onClick={close}
          />
        </div>


        <div className="p-8 pb-0 w-full">
          {isError && error !== null && (
            <div className="p-4 bg-error rounded-lg mb-4 text-[#fff]">{error.message}</div>
          )}

          <form onSubmit={handleSubmit(submit)}>
            <div className="w-full mb-8">
              <label className="mb-2 block font-bold">Naam</label>
              <input
                className="w-full p-4 text-text bg-primary rounded-lg"
                type="text"
                placeholder="Pavel Campens"
                {...register('name')}
              />

              {errors?.name !== undefined && (
                <InputError text={errors.name.message} />
              )}
            </div>

            <div className="w-full">
              <label className="mb-2 block font-bold">E-mail</label>
              <input
                className="w-full p-4 text-text bg-primary rounded-lg"
                type="text"
                placeholder="pavel@wild.be"
                {...register('email')}
              />

              {errors?.email !== undefined && (
                <InputError text={errors.email.message} />
              )}
            </div>

            <button
              type="submit"
              disabled={isPending}
              className="bg-accent text-primary p-4 w-full rounded-lg mt-4 hover:bg-accent-hover duration-300 transition-all"
            >
              Voeg administrator toe
            </button>

            <button
              className="text-text text-center w-full mt-4 hover:text-accent"
              onClick={close}
            >
              Cancel
            </button>
          </form>
        </div>
      </>
    </Modal>
  );
};

export default AddAdminModal;
