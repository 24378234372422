import { z } from 'zod';

export enum CustomerState {
  BLOCKED = 'BLOCKED',
  ACTIVE = 'ACTIVE',
  TO_ACTIVATE = 'TO_ACTIVATE',
  INACTIVE = 'INACTIVE',
}

export const customerSchema = z.object({
  id: z.string(),
  createdAt: z.string().datetime({ offset: true }),
  updatedAt: z.string().datetime({ offset: true }),
  email: z.string().email(),
  name: z.string(),
  roles: z.array(z.enum(['CUSTOMER', 'ADMIN'])),
  state: z.nativeEnum(CustomerState),
});

export type CustomerDto = z.infer<typeof customerSchema>;
