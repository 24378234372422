import { z } from 'zod';

// Define the enum for OrderStatus using z.nativeEnum if it's a TypeScript enum or z.enum for regular JS objects
const OrderState = z.enum([
  'UNKNOWN',
  'PAID',
  'PENDING',
  'OPEN',
  'PAYMENT_FAILED',
  'PAYMENT_REQUIRED',
  'CONFIRMED',
  'SHIPPED',
  'DELIVERED',
  'CANCELED',
  'EXPIRED',
  'BLOCKED',
  'SETTLED',
  'CHARGEBACK',
  'REFUNDED',
  'REFUND_PROCESSING',
  'PARTIALLY_REFUNDED',
  'AUTHORISED',
]);

const userState = z.enum(['BLOCKED', 'ACTIVE', 'TO_ACTIVATE', 'INACTIVE']);

export const orderSchema = z.object({
  id: z.string(),
  created_at: z.string().datetime({ offset: true }), // Use .datetime() with correct configuration if necessary
  user_id: z.string().uuid(), // Validate UUID format
  invoice_id: z.string(),
  total_inc_vat: z.number(),
  state: OrderState,
  payment_intent_id: z.string().optional().nullable(), // Optional and can be null

  user_details: z
    .object({
      name: z.string(),
      email: z.string().email(),
      state: userState,
    })
    .optional(),
});

// Type inference to use in TypeScript for type safety
export type OrderDto = z.infer<typeof orderSchema>;
