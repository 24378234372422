type PlaceholderProps = {
  text: string;
  className?: string;
};

const Placeholder = ({ text, className }: PlaceholderProps) => {
  return (
    <div className={
`p-4 bg-lighter min-h-[200px] flex items-center justify-center mb-12 rounded-lg ${className}`
      }>
      <p>{text}</p>
    </div>
  );
};

export default Placeholder;
