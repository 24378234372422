import { Trash } from 'lucide-react';

import { Button } from '../elements';

import { RelatedProduct } from './dto/product.dto';

type RelatedProductsProps = {
  productId: string,
  relatedProducts: RelatedProduct[]
  removeRelatedProduct: (relatedProduct: RelatedProduct) => void
}

const RelatedProducts = ({ relatedProducts, removeRelatedProduct }: RelatedProductsProps) => {
  if (relatedProducts.length === 0) {
    return (
      <div className="p-4 bg-primary">
        <p>Momenteel zijn er nog geen Gerelateerde producten gekoppeld aan dit proudct.</p>
      </div>
    )
  }

  return (
    <ul className="grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3">
      {
        relatedProducts.map(product => {
          return product.variants.map(variant => (
            <li
              key={product.id}
              className={
                `bg-primary 
                 p-4 rounded-lg relative cursor-pointer 
                 bg-no-repeat bg-cover min-h-[200px] mb-4 
                `
              }
              style={{ gridTemplateRows: '40px 1fr 60px' }}
            >
              <div className="w-full h-[200px] mb-4">
                <img
                  className="w-full h-[200px] object-cover rounded-lg"
                  src={`/api/v1/variants/${variant.image_to_display}/image`}
                  alt=""
                />
              </div>

              <span className="text-xl block font-semibold">
                {product.name === '' ? '--' : product.name}
              </span>

              <div className="flex flex-col">
                <span className="text block font-semibold mb-4">
                  € {(variant.price_inc_vat).toFixed(2)}
                </span>

                {
                  product.type === 'TABLE' && 'color' in variant.attributes && (
                    <>
                      <span>
                        hoogte: {variant.attributes.height}
                      </span>
                      <span>
                        breedte: {variant.attributes.width}
                      </span>
                      <span>
                        lengte: {variant.attributes.depth}
                      </span>
                      <span>
                        afwerking: {variant.attributes.finish}
                      </span>
                      <span className="flex gap-4">
                        kleur:
                        <div
                          className="w-[20px] h-[20px] rounded-[50%] border-2 border-solid border-[#000]"
                          style={{ backgroundColor: 'color' in variant.attributes ? variant.attributes.color : 'transparent' }}
                        />
                      </span>
                    </>
                  )
                }

                <span>
                  Levertermijn: {variant.delivery_time}
                </span>
              </div>

              <div className="flex justify-between gap-2 mt-4">
                <Button
                  onClick={() => removeRelatedProduct(product)}
                  icon={<Trash />}
                  variant="delete"
                />
              </div>
            </li>
          ))
        })
      }
    </ul>
  )
}

export default RelatedProducts;
