import { X } from 'lucide-react';
import { useEffect, useState } from 'react'

import { useModalStore } from '../../../stores/modalStore';
import { useToastStore } from '../../../stores/toastStore';
import { MODALS } from '../../constants';
import InputError from '../../elements/InputError';
import Modal from '../../elements/Modal';
import CropperWrapper from '../../partials/Cropper';
import { readFile } from '../../utils';
import { useAddCategory, useCategoryForm } from '../hooks';

type AddCategoryModalProps = {
  categoryId: string;
};

const AddCategoryModal = ({ categoryId }: AddCategoryModalProps) => {
  const modalStore = useModalStore();
  const { isPending, isError, error, addCategory } = useAddCategory();
  const { successToast, errorToast } = useToastStore();

  const [imageSrc, setImageSrc] = useState<null | string>(null)

  const {
    handleSubmit,
    register,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useCategoryForm({
    name: '',
    parentId: categoryId,
    description: '',
    imageSrc: ''
  });

  useEffect(() => {
    reset({ parentId: categoryId });
  }, [reset, categoryId]);

  const close = () => {
    setImageSrc(null)
    modalStore.closeModal(MODALS.ADD_CATEGORY);
  };

  const submit = async () => {
    try {
      await addCategory(getValues());
      reset();
      close();
      successToast('Categorie toegevoegd');
    } catch (err) {
      console.log(err);
      errorToast('Er is iets misgegaan bij het toevoegen van de categorie');
    }
  };

  const onCropComplete = (imageSrc: string) => {
    setValue('imageSrc', imageSrc)
  };

  const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0]
      const imageDataUrl = await readFile(file)
      setImageSrc(imageDataUrl)
    }
  }

  console.log(imageSrc)

  return (
    <Modal id={MODALS.ADD_CATEGORY}>
      <>
        <div className="relative pt-4">
          <h1 className="ml-8 text-text text-2xl font-bold">Nieuwe categorie</h1>
          <X
            className="absolute right-0 top-0 text-text cursor-pointer"
            onClick={close}
          />
        </div>

        {isError && error !== null && <div>{error.message}</div>}

        <div className="p-8 pb-0 w-full">
          <form onSubmit={handleSubmit(submit)}>
            <div className="w-full mb-4">
              <label className="mb-2 block font-bold">Naam van de categorie</label>
              <input
                className="w-full p-4 text-text bg-primary rounded-lg"
                type="text"
                placeholder="Mortex salontafels"
                {...register('name')}
              />

              {errors?.name !== undefined && (
                <InputError text={errors.name.message} />
              )}
            </div>

            <div className="w-full mb-4">
              <label className="mb-2 block font-bold">Omschrijving van de categorie</label>
              <textarea
                className="w-full p-4 text-text bg-primary rounded-lg"
                placeholder="In deze categorie vind je alle Mortex salontafels"
                {...register('description')}
              />

              {errors?.description !== undefined && (
                <InputError text={errors.description.message} />
              )}
            </div>

            <div>
              <label className="mb-2 block font-bold">Afbeelding</label>


              {!imageSrc && imageSrc !== '' && (
                <>
                  <label htmlFor="file-upload" className="w-full p-4 bg-primary flex justify-center items-center border-dotted border-accent border-[3px] cursor-pointer rounded-lg">
                    Klik hier om een afbeelding te kiezen voor de categorie
                  </label>
                  <input
                    className="hidden"
                    id="file-upload"
                    type="file"
                    onChange={onFileChange}
                    accept="image/*"
                  />
                </>
              )}

              {
                imageSrc && (
                  <div className="relative h-[300px] w-full">
                    <div
                      className="absolute right-4 top-4 w-[30px] h-[30px] rounded-[50%] z-50 bg-[#fff] flex justify-center items-center cursor-pointer"
                      onClick={() => setImageSrc(null)}
                    >
                      <X />
                    </div>

                    <CropperWrapper
                      imgSrc={imageSrc}
                      onCropComplete={onCropComplete}
                    />
                  </div>
                )
              }

              {
                errors?.imageSrc !== undefined && (imageSrc === '' || imageSrc === null) && (
                  <InputError text="Kies een afbeelding voor de categorie" />
                )
              }

            </div>

            <button
              type="submit"
              disabled={isPending}
              className="bg-accent text-primary p-4 w-full rounded-lg mt-4 hover:bg-accent-hover duration-300 transition-all"
            >
              Voeg categorie toe
            </button>

            <button
              className="text-text text-center w-full mt-4 hover:text-accent"
              onClick={close}
            >
              Cancel
            </button>
          </form>
        </div>
      </>
    </Modal>
  );
};

export default AddCategoryModal;
