import { X } from 'lucide-react';
import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { v4 as uuidv4 } from 'uuid';

import { Image, Product } from './dto/product.dto';
import { useUploadImages } from './hooks';

type ImagesProps = {
  images: Image[];
  newProduct: boolean;
  product: Product
  addImage: (image: Image) => void;
  deleteImage: (imagePath: string, imageId: string) => void;
};

const Images = ({ images, product, newProduct, deleteImage }: ImagesProps) => {
  const { uploadImages, isPending, isError, error } = useUploadImages();

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (newProduct) {
        acceptedFiles.forEach(file => {

          const id = uuidv4();

          product.images.push({
            id,
            path: file.name,
            src: '',
            rawSrc: URL.createObjectURL(file)
          });

          const reader = new FileReader();
          reader.onload = () => {
            product.images.forEach(image => {
              if (image.id === id) {
                image.src = reader.result;
              }
            });
          };

          reader.readAsDataURL(file);
        });
        return;
      }

      // Convert files to FormData to send raw bytes
      const formData = new FormData();
      acceptedFiles.forEach(file => {
        formData.append('images', file);
      });

      uploadImages(product.id, acceptedFiles);
    },
    [product, newProduct, uploadImages],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleDeleteImage = (imagePath: string, imageId: string) => {
    try {
      deleteImage(imagePath, imageId)
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <div>
      {isPending && <div>Bezig met het verwerken van de afbeeldingen</div>}

      {isError && <div>Error: {error?.message}</div>}

      <div
        className="bg-primary p-4 text-center min-h-[100px] flex justify-center items-center mb-4 cursor-pointer"
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the files here ...</p>
        ) : (
          <p>
            Klik hier om een afbeelding op te laden of sleep de afbeeldingen
            hierop om deze op te laden
          </p>
        )}
      </div>

      <ul className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
        {images.map((image) => {
          let imgSrc: null | ArrayBuffer | string = `/api/v1/images/${product.id}/${image.path}`;


          if (image.rawSrc && typeof image.rawSrc === 'string') {
            imgSrc = image.rawSrc;
          } else if (newProduct && typeof image.src === 'string') {
            imgSrc = image.src;
          }

          return (
            <li className="relative" key={image.path}>
              <div
                className="absolute flex justify-center items-center p-1.5 right-2 top-2 bg-primary rounded-[50%] height-[30px] width-[30px] cursor-pointer"
                onClick={() => handleDeleteImage(image.path, image.id)}
              >
                <X className="text-error" />
              </div>
              <img
                className="w-full h-48 object-cover rounded-lg"
                src={imgSrc}
                alt={image.path}
              />
            </li>
          )
        })}
      </ul>
    </div>
  );
};

export default Images;
