import { useState } from 'react';

import { SortRule } from '../model/SortRule';
import useSortRules from '../pagination/hooks';
import { Pagination } from '../partials';

import { usePaginatedCustomers } from './hooks';
import UsersGrid from './UsersGrid';

const CustomersPage = () => {
  const itemsPerPage = 9;
  const [searchFilter, setSearchFilter] = useState('');
  const [page, setPage] = useState(1);
  const { sortRules, updateSortRule } = useSortRules([
    new SortRule('Status', 'state', 'c.state'),
    new SortRule('Naam', 'name', 'c.name'),
    new SortRule('Email', 'email', 'c.email'),
    new SortRule('Aangemaakt op', 'created_at', 'c.created_at'),
  ]);

  const { data, isError, error } = usePaginatedCustomers(
    page,
    itemsPerPage,
    searchFilter,
    sortRules
  );

  if (isError) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      <main
        className="p-4 md:p-16"
      >
        <div className="flex gap-4 items-center mb-4 justify-between">
          <h1 className="font-bold">Klanten</h1>
        </div>

        <div className="flex gap-4">
          <input
            className="w-full p-4 mb-4 rounded-lg"
            type="email"
            placeholder="Zoek op naam, e-mail, ..."
            value={searchFilter}
            onChange={(ev) => setSearchFilter(ev.target.value)}
          />

          <Pagination
            totalItems={data?.pagination.totalItems ?? 10}
            itemsPerPage={itemsPerPage}
            currentPage={page}
            onPageChange={setPage}
          />
        </div>

        <UsersGrid
          data={data?.customers ?? []}
          onSortRulesUpdate={updateSortRule}
          sortRules={sortRules}
          type="customers"
        />
      </main>
    </>
  );
};

export default CustomersPage;
