import * as RCheckbox from '@radix-ui/react-checkbox';
import { CheckIcon } from 'lucide-react';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';

interface CheckboxProps<T extends FieldValues> {
  name: Path<T>;
  control: Control<T>;
  classname?: string;
}

const Checkbox = <T extends FieldValues>({
  name,
  control,
  classname,
}: CheckboxProps<T>) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <div className={classname}>
          <RCheckbox.Root
            className="CheckboxRoot"
            checked={field.value}
            onCheckedChange={(checked) => field.onChange(checked)}
          >
            <RCheckbox.Indicator className="CheckboxIndicator">
              <CheckIcon />
            </RCheckbox.Indicator>
          </RCheckbox.Root>
        </div>
      )}
    />
  );
};

export default Checkbox;

