import { z } from 'zod';

// Attributes Schema
export const attributesSchema = z.object({
  color: z.string(),
  finish: z.string(),
  width: z.string(),
  height: z.string(),
  depth: z.string(),
}).or(z.object({}));

// Variant Schema
export const variantSchema = z.object({
  id: z.string(),
  price_inc_vat: z.coerce.number(),
  vat: z.coerce.number(),
  stock: z.coerce.number(),
  attributes: attributesSchema,
  notes: z.string().nullable(),
  delivery_time: z.string().nullable(),
  display_in_webshop: z.boolean().default(true),
  image_to_display: z.string().nullable(),
  image_src: z.string().optional(),
  favourite: z.boolean().default(false),
  lower_threshold_warning: z.coerce.number().nullish(),
  amount: z.coerce.number().nullish(),
});

export const imageSchema = z.object({
  id: z.string(),
  path: z.string(),
  rawSrc: z
    .union([z.string(), z.instanceof(ArrayBuffer)])
    .transform((imageSrc) =>
      typeof imageSrc === 'string'
        ? imageSrc.replace(
            /data:image\/(?:jpeg|jpg|png|gif|bmp|webp|svg\+xml);base64,/,
            ''
          )
        : imageSrc
    )
    .optional()
    .nullable(),
  src: z
    .union([z.string(), z.instanceof(ArrayBuffer)])
    .transform((imageSrc) =>
      typeof imageSrc === 'string'
        ? imageSrc.replace(
            /data:image\/(?:jpeg|jpg|png|gif|bmp|webp|svg\+xml);base64,/,
            ''
          )
        : imageSrc
    )
    .optional()
    .nullable(),
});

export const imagesSchema = z.array(imageSchema);

export const relatedProductSchema = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string(),
  images: z.array(
    z.object({
      src: z.union([z.string(), z.instanceof(ArrayBuffer)]).nullish(),
      id: z.string(),
      path: z.string(),
    })
  ),
  variants: z.array(variantSchema),
  type: z.string(),
});

export const linkedTutorialSchema = z.object({
  id: z.string(),
});

export const baseProductSchema = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string(),
  type: z.string().default('table'),
  category_id: z.string().optional(),
  notes: z.string().nullable(),
  variants: z.array(variantSchema),
});

// Extended Product Schema to include Variants, Images, and RelatedProducts
export const productSchema = baseProductSchema.extend({
  images: imagesSchema,
  related_products: z.array(relatedProductSchema),
  linked_tutorials: z.array(linkedTutorialSchema),
});

export const updateProductSchema = productSchema.extend({
  variants: z.array(
    variantSchema.extend({
      price_inc_vat: z.preprocess(
        (val) => (typeof val === 'string' ? Number(val) : val),
        z.number()
      ),
      vat: z.preprocess(
        (val) => (typeof val === 'string' ? Number(val) : val),
        z.number()
      ),
      stock: z.preprocess(
        (val) => (typeof val === 'string' ? Number(val) : val),
        z.number()
      ),
    })
  ),
});

export const addProductSchema = productSchema.extend({
  variants: z.array(
    variantSchema.extend({
      price_inc_vat: z.preprocess(
        (val) => (typeof val === 'string' ? Number(val) : val),
        z.number()
      ),
      vat: z.preprocess(
        (val) => (typeof val === 'string' ? Number(val) : val),
        z.number()
      ),
      stock: z.preprocess(
        (val) => (typeof val === 'string' ? Number(val) : val),
        z.number()
      ),
    })
  ),
});

export type BaseProduct = z.infer<typeof baseProductSchema>;
export type Product = z.infer<typeof productSchema>;
export type LinkedTutorial = z.infer<typeof linkedTutorialSchema>;
export type RelatedProduct = z.infer<typeof relatedProductSchema>;
export type Image = z.infer<typeof imageSchema>;
export type Variant = z.infer<typeof variantSchema>;
