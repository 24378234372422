import { z } from 'zod';

export const updateCustomerSchema = z.object({
  name: z.string().min(2, { message: 'Vul een geldige naam in.' }),
  email: z.string().min(2, { message: 'Vul een geldig e-mailadres in.' }),
  state: z.string().min(2, { message: 'Vul een geldige staat in.' }),
  id: z.string().uuid({ message: 'Vul een geldige UUID in.' }),
});

export type UpdateCustomerDto = z.infer<typeof updateCustomerSchema>;
