
import { Info } from 'lucide-react';
import { useParams } from 'react-router-dom';

import { useToastStore } from '../../stores/toastStore';
import Badge from '../elements/Badge';
import Error from '../Error';
import Loading from '../partials/Loading';
import { downloadFile } from '../utils';

import { useOrderById, useUpdateOrderState } from './hooks';


type OrderDetailsContainerProps = {
  orderId: string;
}

const OrderDetailsContainer = ({ orderId }: OrderDetailsContainerProps) => {
  const { data, isPending, isError, error } = useOrderById(orderId)
  const { errorToast, successToast } = useToastStore();
  const { updateOrderState } = useUpdateOrderState();

  const downloadInvoice = async () => {
    const url = `/api/v1/orders/download/${data?.invoice_id}.pdf`;
    const fileName = `${orderId}.pdf`;

    await downloadFile(url, fileName);
  };

  const markAsShipped = async () => {
    try {
      await updateOrderState({ orderId, orderState: 'SHIPPED' });
      successToast('Order gemarkeerd als verzonden');
    } catch (error) {
      errorToast('Er is een fout opgetreden');
    }
  };

  const stateColorMap: Record<string, string> = {
    BLOCKED: '#ffbbbb',
    ACTIVE: '#b1e9c8',
    TO_ACTIVATE: '#ffad5d',
    PENDING: '#ffad5d', // Example color for PENDING
    PAYMENT_REQUIRED: '#ffbbbb', // Example color for PAYMENT_REQUIRED
    CONFIRMED: '#5bc0de', // Example color for CONFIRMED
    SHIPPED: '#5cb85c', // Example color for SHIPPED
    DELIVERED: '#0275d8', // Example color for DELIVERED
    CANCELED: '#ffbbbb', // Example color for CANCELED
    UNKNOWN: '#eee', // Example color for UNKNOWN
    PAID: '#b1e9c8', // Example color for PAID
    OPEN: '#ffad5d', // Example color for OPEN (same as PENDING)
    PAYMENT_FAILED: '#ffbbbb', // Example color for PAYMENT_FAILED
    EXPIRED: '#6c757d', // Example color for EXPIRED
    SETTLED: '#5bc0de', // Example color for SETTLED
    CHARGEBACK: '#ffbbbb', // Example color for CHARGEBACK
    REFUNDED: '#0275d8', // Example color for REFUNDED
    REFUND_PROCESSING: '#f0ad4e', // Example color for REFUND_PROCESSING
    PARTIALLY_REFUNDED: '#5bc0de', // Example color for PARTIALLY_REFUNDED
    AUTHORISED: '#b1e9c8', // Example color for AUTHORISED
  };

  const stateTranslationMap: Record<string, string> = {
    PENDING: 'In afwachting',
    PAYMENT_REQUIRED: 'Betaling vereist',
    CONFIRMED: 'Bevestigd',
    SHIPPED: 'Verzonden',
    DELIVERED: 'Geleverd',
    CANCELED: 'Geannuleerd',
    UNKNOWN: 'Onbekend',
    PAID: 'Betaald',
    OPEN: 'In afwachting',
    PAYMENT_FAILED: 'Betaling mislukt',
    EXPIRED: 'Verlopen',
    BLOCKED: 'Geblokkeerd',
    SETTLED: 'Settled',
    CHARGEBACK: 'Chargeback',
    REFUNDED: 'Terugbetaald',
    REFUND_PROCESSING: 'Refund processing',
    PARTIALLY_REFUNDED: 'Gedeeltelijk terugbetaald',
    AUTHORISED: 'Geautoriseerd',
  };


  return (
    <div className="mx-auto max-w-[900px]">
      <div className="flex gap-4 items-center mb-8 justify-between">
        <h1 className="font-bold mb-0">Order details</h1>
      </div>

      <div>
        {isPending && <Loading />}
        {isError && (
          <Error error={error} />
        )}
      </div>

      <div className="bg-lighter p-4 rounded-lg mb-4">
        <div className="flex justify-between items-start mb-8 gap-4 flex-col sm:flex-row">
          <div className="flex justify-start items-center gap-4">
            <Info />
            <h2 className="text-xl font-bold">
              Info
            </h2>
          </div>
        </div>

        {
          data && (
            <div className="flex gap-2">
              <label>Status:</label>
              <div className="relative ml-16">
                <Badge
                  color={stateColorMap[data.state]}
                  text={stateTranslationMap[data.state]}
                />
              </div>

            </div>
          )
        }


      </div>

      <div className="bg-lighter p-4 rounded-lg mb-4">
        <div className="flex justify-between items-start mb-8 gap-4 flex-col sm:flex-row">
          <div className="flex justify-start items-center gap-4">
            <Info />
            <h2 className="text-xl font-bold">
              Acties
            </h2>
          </div>
        </div>

        <div className="w-full mb-4 p-4 bg-[#000] text-[#fff] rounded-lg text-center">
          <a
            href={`https://my.mollie.com/dashboard/payments/${data?.mollie_id}`}
            target="_blank"
            className="w-full cursor-pointer flex justify-center"
          >
            Bekijk betaling op Mollie
          </a>
        </div>

        <div className="w-full mb-4 p-4 bg-[#000] text-[#fff] rounded-lg text-center">
          <a
            href="#"
            onClick={markAsShipped}
            className="w-full cursor-pointer flex justify-center"
          >
            Markeer als verzonden
          </a>
        </div>

        <div className="w-full p-4 bg-[#000] text-[#fff] rounded-lg text-center">
          <a
            href="#"
            onClick={downloadInvoice}
            className="w-full cursor-pointer flex justify-center"
          >
            Download factuur
          </a>
        </div>
      </div>

      <div className="bg-lighter p-4 rounded-lg mb-4">
        <div className="flex justify-between items-start mb-8 gap-4 flex-col sm:flex-row">
          <div className="flex justify-start items-center gap-4">
            <Info />
            <h2 className="text-xl font-bold">
              Factuur-gegevens
            </h2>
          </div>
        </div>

        {
          data && (
            <>
              <div className="mb-4">
                <div className="flex gap-2">
                  <label>Prijs incl BTW:</label>
                  <span>€{data.total_inc_vat.toFixed(2)}</span>
                </div>
              </div>


              <div>
                <div className="flex gap-2">
                  <label>Land:</label>
                  <span>{data.invoice_details.country}</span>
                </div>
                <div className="flex gap-2">
                  <label>Stad:</label>
                  <span>{data.invoice_details.city}</span>
                </div>
                <div className="flex gap-2">
                  <label>Postcode:</label>
                  <span>{data.invoice_details.postal_code}</span>
                </div>
                <div className="flex gap-2">
                  <label>Straat:</label>
                  <span>{data.invoice_details.street}</span>
                </div>
                <div className="flex gap-2">
                  <label>Nummer:</label>
                  <span>{data.invoice_details.number}</span>
                </div>
              </div>
            </>
          )
        }
      </div>

      <div className="bg-lighter p-4 rounded-lg mb-4">
        <div className="flex justify-between items-start mb-8 gap-4 flex-col sm:flex-row">
          <div className="flex justify-start items-center gap-4">
            <Info />
            <h2 className="text-xl font-bold">
              Lever gegevens
            </h2>
          </div>
        </div>

        {
          data && (
            <>
              {
                data.delivery_details.delivery_method === 'pickup' && (
                  <div className="bg-primary p-4 rounded-lg">
                    Ophalen bij de winkel
                  </div>
                )
              }
              {data.delivery_details.delivery_method === 'delivery' && (
                <>
                  <div className="flex gap-2">
                    <label>Land:</label>
                    <span>{data.delivery_details.country}</span>
                  </div>
                  <div className="flex gap-2">
                    <label>Stad:</label>
                    <span>{data.delivery_details.city}</span>
                  </div>
                  <div className="flex gap-2">
                    <label>Postcode:</label>
                    <span>{data.delivery_details.postal_code}</span>
                  </div>
                  <div className="flex gap-2">
                    <label>Straat:</label>
                    <span>{data.delivery_details.street}</span>
                  </div>
                  <div className="flex gap-2">
                    <label>Nummer:</label>
                    <span>{data.delivery_details.number}</span>
                  </div>
                </>
              )}
            </>
          )
        }
      </div>

      <div className="bg-lighter p-4 rounded-lg mb-4">
        <div className="flex justify-between items-start mb-8 gap-4 flex-col sm:flex-row">
          <div className="flex justify-start items-center gap-4">
            <Info />
            <h2 className="text-xl font-bold">
              Bestelde producten
            </h2>
          </div>
        </div>

        {
          data && data.ordered_items.map((product, idx) => (
            <div
              className="grid bg-primary gap-4 mb-4 grid-cols-1 sm:grid-cols-2 justify-center items-center p-4 rounded-lg"
              key={idx}
            >
              <div className="w-full h-[190px]">
                <img
                  className="w-full h-[100%] object-cover rounded-lg"
                  src={`/api/v1/variants/${product?.image_to_display}/image`}
                  alt=""
                />
              </div>
              <div className="flex flex-col">
                <span className="text block font-semibold mb-4">
                  {product.quantity} x € {(product.price_inc_vat).toFixed(2)}
                </span>

                {
                  product.type === 'TABLE' && 'color' in product.attributes && (
                    <>
                      <span>
                        hoogte: {product.attributes.height}
                      </span>
                      <span>
                        breedte: {product.attributes.width}
                      </span>
                      <span>
                        lengte: {product.attributes.depth}
                      </span>
                      <span>
                        afwerking: {product.attributes.finish}
                      </span>
                      <span className="flex gap-4">
                        kleur:
                        <div className="w-[20px] h-[20px] rounded-[50%] border-2 border-solid border-[#000]" style={{ backgroundColor: product.attributes.color }} />
                      </span>
                    </>
                  )
                }

                <span>
                  Levertermijn: {product.delivery_time}
                </span>
              </div>
            </div>

          ))
        }
      </div>

      <div className="bg-lighter p-4 rounded-lg mb-4">
        <div className="flex justify-between items-start mb-8 gap-4 flex-col sm:flex-row">
          <div className="flex justify-start items-center gap-4">
            <Info />
            <h2 className="text-xl font-bold">
              Betaal-geschiedenis
            </h2>
          </div>
        </div>

        {
          data?.order_history.length === 0 && (
            <div className="p-4 bg-primary rounded-lg">Geen geschiedenis</div>
          )
        }

        <ul>
          {data?.order_history.map((item, index) => (
            <li key={index} className="grid grid-cols-3 gap-4 bg-primary p-4 mb-4">
              <div className="flex flex-col gap-2">
                <label className="font-bold text-sm">Van</label>
                <span>{stateTranslationMap[item.from ?? ''] ?? '--'}</span>
              </div>

              <div className="flex flex-col gap-2">
                <label className="font-bold text-sm">Naar</label>
                <span>{stateTranslationMap[item.to]}</span>
              </div>

              <div className="flex flex-col gap-2">
                <label className="font-bold text-sm">Op</label>
                <span>{new Date(item.created_at).toLocaleString()}</span>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}


const OrderDetailsPage = () => {
  const { orderId } = useParams();

  return (
    <>
      <main className="p-4 md:p-16 relative min-h-[calc(100vh-120px)]">
        {orderId && <OrderDetailsContainer orderId={orderId} />}
      </main>
    </>
  );
};

export default OrderDetailsPage;
