import { useRef } from 'react';

type VideoplayerProps = {
  filePath: string;
};

const VideoPlayer = ({ filePath }: VideoplayerProps) => {
  const videoRef = useRef<null | HTMLVideoElement>(null);
  const videoSrc = `/api/v1/tutorials/play/${filePath}`

  return (
    <video
      controls
      className="w-full h-full rounded-lg"
      ref={videoRef}
    >
      <source
        src={videoSrc}
        type="video/mp4"
      /> {/* Adjust type based on your file types */}
      Your browser does not support the video tag.
    </video>
  );
};

export default VideoPlayer;


