import { useMutation, useQuery } from '@tanstack/react-query';

import { QUERY_KEYS } from '../constants';
import { SortRule } from '../model/SortRule';
import { doFetch } from '../utils';

import { orderDetailsSchema } from './dto/order-details.dto';
import { paginatedOrdersSchema } from './dto/paginated-orders.dto';

const URLS = {
  GET_PAGINATED_ORDERS: '/api/v1/orders/paginated',
  GET_ORDER_BY_ID: (orderId: string) => `/api/v1/orders/${orderId}`,
  UPDATE_ORDER: (orderId: string) => `/api/v1/orders/${orderId}`,
};

export const usePaginatedOrders = (
  page: number,
  itemsPerPage: number,
  filter: string,
  sortRules: SortRule[]
) => {
  return useQuery({
    queryKey: [QUERY_KEYS.orders, page, itemsPerPage, filter, sortRules],
    queryFn: async () => {
      const { status, data } = await doFetch(
        URLS.GET_PAGINATED_ORDERS,
        'POST',
        {
          page,
          itemsPerPage,
          filter,
          sortRules: sortRules.filter((rule) => rule.sortOrder !== ''),
        }
      );

      if (status !== 200) {
        throw new Error();
      }

      return paginatedOrdersSchema.parse(data);
    },
  });
};

export const useOrderById = (orderId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.orders, orderId],
    queryFn: async () => {
      const { status, data } = await doFetch(
        URLS.GET_ORDER_BY_ID(orderId),
        'GET'
      );

      if (status !== 200) {
        throw new Error();
      }

      return orderDetailsSchema.parse(data);
    },
  });
};

export const useUpdateOrderState = () => {
  const mutation = useMutation({
    mutationFn: async ({
      orderId,
      orderState,
    }: {
      orderId: string;
      orderState: string;
    }) => {
      const { status, data } = await doFetch(
        URLS.UPDATE_ORDER(orderId),
        'PUT',
        {
          orderState,
        }
      );

      if (status !== 200) {
        throw new Error(data.message);
      }
    },
  });

  return {
    updateOrderState: mutation.mutateAsync,
    isPending: mutation.isPending,
    isError: mutation.isError,
    error: mutation.error,
  };
};
