import { ChevronLeft, ChevronRight } from 'lucide-react';

type PaginationProps = {
  totalItems: number;
  itemsPerPage: number;
  currentPage: number;
  onPageChange: (page: number) => void;
  className?: string;
};
const Pagination = ({
  totalItems,
  itemsPerPage,
  currentPage,
  onPageChange,
  className = ''
}: PaginationProps) => {
  const goToPreviousPage = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const goToNextPage = () => {
    if (currentPage < totalItems / itemsPerPage) {
      onPageChange(currentPage + 1);
    }
  };

  return (
    <div className={`flex h-[55px] ${className}`}>
      <div
        className={`bg-[#fff] flex justify-center items-center rounded-l-lg border-r border-[#000] ${currentPage === 1 ? 'text-[#ccc]' : 'text-[#000]'
          }`}
      >
        <ChevronLeft onClick={goToPreviousPage} />
      </div>

      <div className="bg-[#fff] min-w-[60px] flex justify-center items-center text-[#000] border-l border-r border-[#000]">
        {currentPage}/{Math.ceil(totalItems / itemsPerPage) || 1}
      </div>

      <div
        className={`bg-[#fff] flex justify-center items-center rounded-r-lg border-l border-[#000] ${currentPage === Math.ceil(totalItems / itemsPerPage) ? 'text-[#ccc]' : 'text-[#000]'
          }`}
      >
        <ChevronRight onClick={goToNextPage} />
      </div>
    </div>


  );
};

export default Pagination;
