import { ChevronDown, ChevronUp, ChevronsUpDown } from 'lucide-react';

import { SortRule } from '../model/SortRule';

type SortHeaderProps = {
  sortRule: SortRule;
}

const SortHeader = ({ sortRule }: SortHeaderProps) => {
  switch (sortRule.sortOrder) {
    case 'ASC':
      return <ChevronDown />
    case 'DESC':
      return <ChevronUp />
    case '':
      return <ChevronsUpDown />
  }
}

export default SortHeader;
