import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';

import ApiError from '../ApiError';
import { QUERY_KEYS } from '../constants';
import { SortRule } from '../model/SortRule';
import { doFetch } from '../utils';

import {
  AddAdministratorDto,
  addAdministratorSchema,
} from './dto/add-administrator.dto';
import { administratorSchema } from './dto/administrator.dto';
import {
  EditAdministratorDto,
  editAdministratorSchema,
} from './dto/edit-administrator.dto';
import { paginatedAdministratorsSchema } from './dto/paginated-administrators.dto';

const URLS = {
  ADD_ADMINISTRATOR: '/api/v1/admins',
  UPDATE_ADMINISTRATOR: (administratorId: string) =>
    `/api/v1/admins/${administratorId}`,
  DELETE_ADMINISTRATOR: (administratorId: string) =>
    `/api/v1/admins/${administratorId}`,
  GET_PAGINATED_ADMINISTRATORS: '/api/v1/admins/paginated',
  GET_ADMINISTRATOR_BY_ID: (administratorId: string) =>
    `/api/v1/admins/${administratorId}`,

  SEND_PASSWORD_RESET: '/api/v1/admins/actions/send-password-reset',
};

export const useAddAdministratorForm = (administrator: AddAdministratorDto) => {
  return useForm<AddAdministratorDto>({
    defaultValues: administrator,
    resolver: zodResolver(addAdministratorSchema),
  });
};

export const useEditAdministratorForm = (
  administrator: EditAdministratorDto
) => {
  return useForm<EditAdministratorDto>({
    defaultValues: administrator,
    resolver: zodResolver(editAdministratorSchema),
  });
};

export const useEditAdministrator = () => {
  const queryClient = useQueryClient();
  const { isPending, isError, mutate, error } = useMutation({
    mutationFn: async (editAdministratorDto: EditAdministratorDto) => {
      const { name, email, id } = editAdministratorDto;

      const { status, data } = await doFetch(
        URLS.UPDATE_ADMINISTRATOR(id),
        'PUT',
        {
          name,
          email,
        }
      );

      if (status !== 200) {
        throw new ApiError(data.error, status);
      }

      return data;
    },
  });

  const updateAdministrator = async (
    editAdministratorDto: EditAdministratorDto
  ): Promise<EditAdministratorDto> => {
    return new Promise((resolve, reject) => {
      mutate(editAdministratorDto, {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.administrators],
          });
          resolve(editAdministratorDto);
        },
        onError: reject,
      });
    });
  };

  return {
    isPending,
    isError,
    updateAdministrator,
    error,
  };
};

export const useAddAdministrator = () => {
  const queryClient = useQueryClient();
  const { isPending, isError, mutate, error, reset } = useMutation({
    mutationFn: async (addAdministratorDto: AddAdministratorDto) => {
      const { name, email } = addAdministratorDto;

      const { status, data } = await doFetch(URLS.ADD_ADMINISTRATOR, 'POST', {
        name,
        email,
      });

      if (status !== 201) {
        throw new Error(data.error);
      }

      return data;
    },
  });

  const addAdministrator = async (
    addAdministratorDto: AddAdministratorDto
  ): Promise<AddAdministratorDto> => {
    return new Promise((resolve, reject) => {
      mutate(addAdministratorDto, {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.administrators],
          });
          resolve(addAdministratorDto);
        },
        onError: reject,
      });
    });
  };

  return {
    isPending,
    isError,
    addAdministrator,
    reset,
    error,
  };
};

export const usePaginatedAdmins = (
  page: number,
  itemsPerPage: number,
  filter: string,
  sortRules: SortRule[]
) => {
  return useQuery({
    queryKey: [
      QUERY_KEYS.administrators,
      page,
      itemsPerPage,
      filter,
      sortRules,
    ],
    queryFn: async () => {
      const { status, data } = await doFetch(
        URLS.GET_PAGINATED_ADMINISTRATORS,
        'POST',
        {
          page,
          itemsPerPage,
          filter,
          sortRules: sortRules.filter((rule) => rule.sortOrder !== ''),
        }
      );

      if (status !== 200) {
        throw new Error();
      }

      return paginatedAdministratorsSchema.parse(data);
    },
  });
};

export const useAdministratorById = (administratorId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.administrators, administratorId],
    queryFn: async () => {
      const { status, data } = await doFetch(
        URLS.GET_ADMINISTRATOR_BY_ID(administratorId),
        'GET'
      );

      if (status !== 200) {
        throw new Error();
      }

      return administratorSchema.parse(data);
    },
  });
};

export const useDeleteAdmin = () => {
  const queryClient = useQueryClient();
  const { isPending, reset, isError, mutate, error } = useMutation({
    mutationFn: async (adminId: string) => {
      const { status, data } = await doFetch(
        URLS.DELETE_ADMINISTRATOR(adminId),
        'DELETE'
      );

      if (status !== 200) {
        throw new Error(data.message);
      }
    },
  });

  const deleteAdmin = async (adminId: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      mutate(adminId, {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.administrators],
          });
          resolve();
        },
        onError: reject,
      });
    });
  };

  return {
    isPending,
    isError,
    deleteAdmin,
    error,
    reset,
  };
};

export const useSendPasswordReset = () => {
  const mutation = useMutation({
    mutationFn: async (email: string) => {
      const { status, data } = await doFetch(URLS.SEND_PASSWORD_RESET, 'POST', {
        email,
      });

      if (status !== 200) {
        throw new Error(data.message);
      }
    },
  });

  return {
    sendPasswordReset: mutation.mutateAsync,
    isPending: mutation.isPending,
    isError: mutation.isError,
    error: mutation.error,
  };
};
