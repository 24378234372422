import { X } from 'lucide-react';
import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import { useModalStore } from '../../stores/modalStore';
import { MODALS } from '../constants';

import { useUploadImages } from './hooks';
import DeleteRealisationModal from './modal/DeleteRealisationModal';

type ImagesProps = {
  images: {
    path: string;
    id: string;
  }[];
};

const Images = ({ images }: ImagesProps) => {
  const modalStore = useModalStore();
  const { uploadImages, isPending, isError, error } = useUploadImages();
  const [realisationToDelete, setRealisationToDelete] = useState<string | null>(null);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      uploadImages(acceptedFiles);
      // Do something with the files
    },
    [uploadImages],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const openDeleteRealisationWarning = (realisationId: string) => {
    setRealisationToDelete(realisationId);
    modalStore.openModal(MODALS.DELETE_REALISATION);
  };

  return (
    <>
      <DeleteRealisationModal realisationId={realisationToDelete} />
      <div>
        {isPending && <div>Bezig met het verwerken van de afbeeldingen</div>}

        {isError && <div>Error: {error?.message}</div>}

        <div
          className="bg-lighter p-4 text-center min-h-[100px] flex justify-center items-center mb-4 cursor-pointer"
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <p>
              Klik hier om een afbeelding op te laden of sleep de afbeeldingen
              hierop om deze op te laden
            </p>
          )}
        </div>

        <ul className="grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3">
          {images.map((image) => (
            <li className="relative border border-accent border-solid rounded-lg" key={image.id}>
              <div
                className="absolute flex justify-center items-center p-1.5 right-2 top-2 bg-[#fff] rounded-[50%] height-[30px] width-[30px] cursor-pointer"
                onClick={() => openDeleteRealisationWarning(image.id)}
              >
                <X className="text-error" />
              </div>
              <img
                className="w-full h-48 object-cover rounded-lg"
                src={`/api/v1/images/realisations/${image.path}`}
                alt={image.path}
              />
            </li>
          ))}
        </ul>
      </div>
    </>

  );
};

export default Images;

