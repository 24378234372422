import { Plus } from 'lucide-react';
import { useNavigate, useParams } from 'react-router-dom';

import { useToastStore } from '../../stores/toastStore';
import { Button } from '../elements';

import ProductContainer from './_productContainer';
import { useAddProduct, useProductForm } from './hooks';

const AddProductPage = () => {
  const toastStore = useToastStore();
  const navigate = useNavigate();
  const { categoryId } = useParams();

  const productForm = useProductForm();
  const { getValues, trigger } = productForm;

  const values = getValues();

  const { addProduct } = useAddProduct();

  const handleAddProduct = async () => {
    try {
      await trigger()
    } catch (err) {
      console.log('error');
      console.log(err);
    }

    try {
      await addProduct({
        ...getValues(),
        category_id: categoryId,
      });

      navigate(`/catalogus/${categoryId}`);
      toastStore.successToast('Product toegevoegd');
    } catch (err) {
      console.log('error');
      console.log(err);
      toastStore.errorToast('Er is iets misgegaan bij het toevoegen van het product');
    }
  };

  const { name } = values;

  return (
    <>
      <main className="p-4 md:p-16 relative min-h-[calc(100vh-120px)]">
        <div className="flex gap-4 items-center mb-16 justify-between">
          <h1 className="font-bold mb-0">{name === '' ? '--' : name}</h1>
          <div className="flex gap-4">
            <Button
              onClick={handleAddProduct}
              variant="primary"
              text="Voeg product toe"
              icon={<Plus />}
            />
          </div>
        </div>

        <ProductContainer productForm={productForm} editing={false} product={values} />
      </main>
    </>
  );
};

export default AddProductPage;
