
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { ToastContainer } from './partials';
import Router from './Router';

const queryClient = new QueryClient();

function App() {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <Router />
        <ToastContainer />
      </QueryClientProvider>
    </>
  );
}

export default App;
