import { Trash, X } from 'lucide-react';

import { useModalStore } from '../../../stores/modalStore';
import { useToastStore } from '../../../stores/toastStore';
import { MODALS } from '../../constants';
import { Button } from '../../elements';
import Modal from '../../elements/Modal';
import { useDeleteRealisation } from '../hooks';

type DeleteRealisationModalProps = {
  realisationId?: string | null;
};

const DeleteRealisationModal = ({
  realisationId,
}: DeleteRealisationModalProps) => {
  const modalStore = useModalStore();
  const { successToast, errorToast } = useToastStore();
  const { isPending, isError, error, deleteRealisation } = useDeleteRealisation();

  const close = () => {
    modalStore.closeModal(MODALS.DELETE_REALISATION);
  };

  const confirmDelete = async () => {
    if (!realisationId) {
      return errorToast('Er is iets misgegaan tijdens het verwijderen van de realisatie');

    }

    try {
      await deleteRealisation(realisationId);
      close();
      successToast('Realisatie verwijderd');
    } catch (err) {
      errorToast('Er is iets misgegaan tijdens het verwijderen van de realisatie')
      console.log(err);
    }
  };

  return (
    <Modal id={MODALS.DELETE_REALISATION}>
      <>
        <div className="relative pt-4">
          <h1 className="ml-8 text-text text-2xl">Verwijder realisation</h1>
          <X
            className="absolute right-0 top-0 text-text cursor-pointer"
            onClick={close}
          />
        </div>

        {isError && error !== null && <div>{error.message}</div>}

        <div className="p-8 pb-0 w-full">
          <p className="p-4 bg-primary mb-4">
            Ben je zeker dat je deze realisatie wenst te verwijderen? Deze actie
            kan niet ongedaan gemaakt worden.
          </p>
          <Button
            onClick={confirmDelete}
            disabled={isPending}
            text="Ja, verwijder realisatie"
            icon={<Trash />}
            variant="delete"
            fullWidth
            className="mb-4"
            style={{ justifyContent: 'center' }}
          />

          <Button
            onClick={close}
            fullWidth
            text="Nee, ga terug"
            variant="tertiary"
            style={{ justifyContent: 'center' }}
          />
        </div>
      </>
    </Modal>
  );
};

export default DeleteRealisationModal;
