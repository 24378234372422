import { Plus } from 'lucide-react';
import { useState } from 'react';

import { useModalStore } from '../../stores/modalStore';
import { MODALS } from '../constants';
import UsersGrid from '../customers/UsersGrid';
import { Button } from '../elements';
import { SortRule } from '../model/SortRule';
import useSortRules from '../pagination/hooks';
import { Pagination } from '../partials';

import { usePaginatedAdmins } from './hooks';
import AddAdminModal from './modal/AddAdminModal';

const AdminsPage = () => {
  const itemsPerPage = 10;
  const modalStore = useModalStore();
  const [searchFilter, setSearchFilter] = useState('');
  const [page, setPage] = useState(1);
  const { sortRules, updateSortRule } = useSortRules([
    new SortRule('Status', 'state', 'c.state'),
    new SortRule('Naam', 'name', 'c.name'),
    new SortRule('Email', 'email', 'c.email'),
    new SortRule('Aangemaakt op', 'created_at', 'c.created_at'),
  ]);

  const { data, isError, error } = usePaginatedAdmins(
    page,
    itemsPerPage,
    searchFilter,
    sortRules
  );

  const openAddAdmin = () => {
    modalStore.openModal(MODALS.ADD_ADMIN);
  };

  if (isError) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      <AddAdminModal />
      <main
        className="p-4 md:p-16"
      >
        <div className="flex-col items-start flex gap-4 md:flex-row md:items-center mb-4 md:justify-between">
          <h1 className="font-bold mb-0">Admins</h1>
          <div className="flex flex-col gap-4 sm:flex-row sm:w-max w-full">
            <Button
              onClick={openAddAdmin}
              variant="primary"
              text="voeg administrator toe"
              icon={<Plus />}
            />
          </div>
        </div>

        <div className="flex gap-4">
          <input
            className="w-full p-4 mb-4 rounded-lg"
            type="email"
            placeholder="Zoek op naam, e-mail, ..."
            value={searchFilter}
            onChange={(ev) => setSearchFilter(ev.target.value)}
          />

          <Pagination
            totalItems={data?.pagination.totalItems ?? 10}
            itemsPerPage={itemsPerPage}
            currentPage={page}
            onPageChange={setPage}
          />
        </div>

        <UsersGrid
          data={data?.admins ?? []}
          onSortRulesUpdate={updateSortRule}
          sortRules={sortRules}
          type="administrators"
        />

      </main>
    </>
  );
};

export default AdminsPage;
