import { MoveLeft, Pipette, X } from 'lucide-react';
import { useEffect, useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import { get } from 'react-hook-form';

import { useModalStore } from '../../../stores/modalStore';
import { MODALS } from '../../constants';
import Checkbox from '../../elements/Checkbox';
import InputError from '../../elements/InputError';
import Modal from '../../elements/Modal';
import { Product, Variant, variantSchema } from '../dto/product.dto';
import { VIEWS, useVariantForm } from '../hooks';

type EditVariantModalProps = {
  editVariant: (variant: Variant) => void;
  variant: Variant | null;
  product: Product
  editing: boolean;
};

const EditVariantModal = ({ editVariant, variant, product, editing }: EditVariantModalProps) => {
  const [activeView, setActiveView] = useState(VIEWS.OVERVIEW);
  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);
  const modalStore = useModalStore();

  const {
    handleSubmit,
    register,
    reset,
    getValues,
    setValue,
    trigger,
    control,
    watch,
    formState: { errors },
  } = useVariantForm(activeView, variant, product.type);

  useEffect(() => {
    if (variant !== null) {
      reset(variant);
    }
  }, [variant, reset])

  watch(['attributes.color', 'image_to_display']);

  const close = () => {
    modalStore.closeModal(MODALS.EDIT_VARIANT);
  };

  const goBack = () => {
    if (activeView === VIEWS.STOCK) {
      setActiveView(VIEWS.IMAGE);
    }

    if (activeView === VIEWS.IMAGE) {
      setActiveView(VIEWS.OVERVIEW);
    }
  }

  const submit = () => {
    try {
      const values = variantSchema.parse(getValues())
      editVariant(values)
      reset();
      close();
      setActiveView(VIEWS.OVERVIEW);
    } catch (err) {
      console.log(err);
    }
  };

  const proceed = async () => {
    await trigger();

    if (activeView === VIEWS.OVERVIEW) {
      setActiveView(VIEWS.IMAGE)
    }

    if (activeView === VIEWS.IMAGE) {
      setActiveView(VIEWS.STOCK);
    }

    if (activeView === VIEWS.STOCK) {
      submit();
    }
  }


  console.log(errors)
  const values = getValues();

  return (
    <Modal id={MODALS.EDIT_VARIANT}>
      <>
        <div className="relative pt-4">
          <h1 className="ml-8 text-text text-2xl font-bold">Wijzig variant - Overzicht</h1>
          <X
            className="absolute right-0 top-0 text-text cursor-pointer"
            onClick={close}
          />
        </div>

        {
          activeView !== VIEWS.OVERVIEW && (
            <div className="flex gap-4 p-8" onClick={goBack}>
              <MoveLeft />
              <span>
                Go terug
              </span>
            </div>
          )
        }

        <div className="p-8 pb-0 w-full">
          <form onSubmit={handleSubmit(proceed)}>
            {
              activeView === VIEWS.OVERVIEW && (
                <>
                  <div className="w-full mb-8">
                    <label className="mb-2 block font-bold">
                      BTW %
                    </label>
                    <input
                      className="w-full p-4 text-text bg-primary rounded-lg"
                      type="number"
                      placeholder="21"
                      {...register('vat', {
                        valueAsNumber: true,
                      })}
                    />

                    {errors?.vat !== undefined && (
                      <InputError text={errors.vat.message} />
                    )}
                  </div>

                  <div className="w-full mb-8">
                    <label className="mb-2 block font-bold">
                      Prijs voor de variant in euro
                    </label>
                    <input
                      className="w-full p-4 text-text bg-primary rounded-lg"
                      type="number"
                      placeholder="199"
                      {...register('price_inc_vat', {
                        valueAsNumber: true,
                      })}
                    />

                    {errors?.price_inc_vat !== undefined && (
                      <InputError text={errors.price_inc_vat.message} />
                    )}
                  </div>

                  {
                    product.type === 'TABLE' && 'color' in values.attributes && (
                      <>
                        <div className="w-full mb-8">
                          <label className="mb-2 block font-bold">
                            Kies de afwerking van de variant
                          </label>
                          <input
                            className="w-full p-4 text-text bg-primary rounded-lg"
                            placeholder="Afgewerkt / onafgewerkt, ..."
                            {...register('attributes.finish')}
                          />

                          {
                            get(errors, 'attributes.finish') && (
                              <InputError text={get(errors, 'attributes.finish').message} />
                            )
                          }
                        </div>
                        <div className="w-full mb-8">
                          <label className="mb-2 block font-bold">
                            Kies de kleur van de variant
                          </label>
                          <div className="flex items-center gap-2">
                            <div
                              className={`p-4 bg-primary rounded-lg border-[3px] border-solid`}
                              onClick={() => setIsColorPickerOpen(!isColorPickerOpen)}
                              style={{ borderColor: values.attributes.color }}
                            >
                              <Pipette />
                            </div>
                            <input
                              className="w-full p-4 text-text bg-primary rounded-lg"
                              placeholder="#000"
                              {...register('attributes.color')}
                            />
                          </div>

                          {
                            get(errors, 'attributes.finish') && (
                              <InputError text={get(errors, 'attributes.finish').message} />
                            )
                          }
                          {
                            isColorPickerOpen && (
                              <HexColorPicker color={values.attributes.color} onChange={(color) => setValue('attributes.color', color)} />
                            )
                          }

                        </div>
                        <div className="w-full mb-8">
                          <label className="mb-2 block font-bold">
                            Hoogte van de variant in cm
                          </label>
                          <input
                            className="w-full p-4 text-text bg-primary rounded-lg"
                            placeholder="Vul de hoogte van het product in in cm"
                            {...register('attributes.height')}
                          />

                          {
                            get(errors, 'attributes.height') && (
                              <InputError text={get(errors, 'attributes.height').message} />
                            )
                          }
                        </div>
                        <div className="w-full mb-8">
                          <label className="mb-2 block font-bold">
                            Breedte van de variant in cm
                          </label>
                          <input
                            className="w-full p-4 text-text bg-primary rounded-lg"
                            placeholder="Vul de prijs in voor de variant"
                            {...register('attributes.width')}
                          />
                          {
                            get(errors, 'attributes.width') && (
                              <InputError text={get(errors, 'attributes.width').message} />
                            )
                          }
                        </div>
                        <div className="w-full mb-8">
                          <label className="mb-2 block font-bold">
                            Lengte van de variant in cm
                          </label>
                          <input
                            className="w-full p-4 text-text bg-primary rounded-lg"
                            placeholder="Vul de prijs in voor de variant"
                            {...register('attributes.depth')}
                          />

                          {
                            get(errors, 'attributes.depth') && (
                              <InputError text={get(errors, 'attributes.depth').message} />
                            )
                          }

                        </div>
                      </>
                    )
                  }
                </>
              )
            }

            {
              activeView === VIEWS.STOCK && (
                <>
                  <div className="w-full mb-8">
                    <label className="mb-2 block font-bold">
                      Zichtbaar in het overzicht van de webshop
                    </label>

                    <Checkbox control={control} name="display_in_webshop" />

                    {errors?.display_in_webshop !== undefined && (
                      <InputError text={errors.display_in_webshop.message} />
                    )}
                  </div>

                  <div className="w-full mb-8">
                    <label className="mb-2 block font-bold">
                      Markeer als favoriet
                    </label>

                    <Checkbox control={control} name="favourite" />

                    {errors?.favourite !== undefined && (
                      <InputError text={errors.favourite.message} />
                    )}
                  </div>

                  <div className="w-full mb-8">
                    <label className="mb-2 block font-bold">Levertermijn</label>
                    <input
                      className="w-full p-4 text-text bg-primary rounded-lg"
                      placeholder="2 dagen, 3 weken, ..."
                      {...register('delivery_time')}
                    />

                    {errors?.delivery_time !== undefined && (
                      <InputError text={errors.delivery_time.message} />
                    )}
                  </div>

                  <div className="w-full mb-8">
                    <label className="mb-2 block font-bold">
                      Stock van de variant in aantal
                    </label>
                    <input
                      className="w-full p-4 text-text bg-primary rounded-lg"
                      type="number"
                      placeholder="100"
                      {...register('stock', {
                        valueAsNumber: true,
                      })}
                    />

                    {errors?.stock !== undefined && (
                      <InputError text={errors.stock.message} />
                    )}
                  </div>

                  <div className="w-full">
                    <label className="mb-2 block font-bold">
                      Waarschuwing bij lage stock vanaf aantal stuks
                    </label>
                    <input
                      className="w-full p-4 text-text bg-primary rounded-lg"
                      type="number"
                      placeholder="0"
                      {...register('lower_threshold_warning', {
                        valueAsNumber: true,
                      })}
                    />

                    {errors?.lower_threshold_warning !== undefined && (
                      <InputError text={errors.lower_threshold_warning.message} />
                    )}
                  </div>
                </>
              )
            }

            {
              activeView === VIEWS.IMAGE && (
                <>
                  <div className="w-full mb-8">
                    <label className="mb-2 block font-bold">
                      Selecteer de afbeelding die moet worden weergegeven
                    </label>

                    <div className="grid grid-cols-3 gap-2">
                      {product.images.map((image) => (
                        <div
                          className={`relative border-[4px] cursor-pointer border-solid  ${getValues('image_to_display') === image.id ? 'border-accent rounded-lg' : 'border-[#fff]'}`}
                          key={image.path}
                          onClick={() => {
                            if (!editing && typeof image.src === 'string') {
                              setValue('image_src', image.src)
                            }

                            setValue('image_to_display', image.id)
                          }}
                        >
                          <img
                            className="w-full h-48 object-cover"
                            src={editing ? `/api/v1/images/${product.id}/${image.path}` : typeof image.src === 'string' ? image.src : ''}
                            alt={image.path}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              )
            }

            {
              activeView === VIEWS.IMAGE && (
                <button
                  className="bg-accent text-primary p-4 w-full rounded-lg mt-4 hover:bg-accent-hover duration-300 transition-all"
                  type="submit"
                >
                  Ga verder
                </button>
              )
            }

            {
              activeView === VIEWS.OVERVIEW && (
                <button
                  className="bg-accent text-primary p-4 w-full rounded-lg mt-4 hover:bg-accent-hover duration-300 transition-all"
                  type="submit"
                >
                  Ga verder
                </button>
              )
            }

            {
              activeView === VIEWS.STOCK && (
                <button
                  type="submit"
                  className="bg-accent text-primary p-4 w-full rounded-lg mt-4 hover:bg-accent-hover duration-300 transition-all"
                >
                  Wijzig variant
                </button>
              )
            }

            <button
              className="text-text text-center w-full mt-4 hover:text-accent"
              onClick={close}
            >
              Cancel
            </button>
          </form>
        </div>
      </>
    </Modal>
  );
};

export default EditVariantModal;

