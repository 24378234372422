import { z } from 'zod';

export const addTutorialSchema = z.object({
  name: z.string().min(3, { message: 'Vul een geldige naam in.' }),
  description: z.string().min(2, {
    message: 'Vul een geldige omschrijving in.',
  }),
  isActive: z.boolean().default(true),
  isPublic: z.boolean().default(true),
});

export type AddTutorialDto = z.infer<typeof addTutorialSchema>;
