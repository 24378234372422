import { Trash, X } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

import { useModalStore } from '../../../stores/modalStore';
import { useToastStore } from '../../../stores/toastStore';
import { MODALS } from '../../constants';
import { Button } from '../../elements';
import Modal from '../../elements/Modal';
import { useDeleteProduct } from '../hooks';


type DeleteProductModalProps = {
  productId: string;
  categoryId: string;
};

const DeleteProductModal = ({
  productId,
  categoryId,
}: DeleteProductModalProps) => {
  const navgiate = useNavigate();
  const { successToast, errorToast } = useToastStore();
  const modalStore = useModalStore();
  const { isPending, isError, error, deleteProduct } = useDeleteProduct();

  const close = () => {
    modalStore.closeModal(MODALS.DELETE_TUTORIAL);
  };

  const confirmDelete = async () => {
    try {
      await deleteProduct(productId);
      close();
      successToast('Product verwijderd');
      navgiate(`/catalogus/${categoryId}`);
    } catch (err) {
      errorToast('Er is iets misgegaan tijdens het verwijderen van het product');
      console.log(err);
    }
  };

  return (
    <Modal id={MODALS.DELETE_TUTORIAL}>
      <>
        <div className="relative pt-4">
          <h1 className="ml-8 text-text text-2xl">Verwijder product</h1>
          <X
            className="absolute right-0 top-0 text-text cursor-pointer"
            onClick={close}
          />
        </div>

        {isError && error !== null && <div>{error.message}</div>}

        <div className="p-8 pb-0 w-full">
          <p className="p-4 bg-primary mb-4">
            Ben je zeker dat je dit product wenst te verwijderen? Deze actie kan
            niet ongedaan gemaakt worden.
          </p>
          <Button
            onClick={confirmDelete}
            disabled={isPending}
            text="Ja, verwijder product"
            icon={<Trash />}
            variant="delete"
            fullWidth
            style={{ justifyContent: 'center' }}
            className="mb-4"
          />

          <Button
            onClick={close}
            fullWidth
            style={{ justifyContent: 'center' }}
            disabled={isPending}
            text="Nee, ga terug"
            variant="tertiary"
          />
        </div>
      </>
    </Modal>
  );
};

export default DeleteProductModal;
