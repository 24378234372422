import { z } from 'zod';

import { CustomerState } from '../../customers/dto/customer.dto';

export const administratorSchema = z.object({
  id: z.string(),
  createdAt: z.string().datetime({ offset: true }),
  updatedAt: z.string().datetime({ offset: true }),
  email: z.string().email(),
  name: z.string(),
  roles: z.array(z.enum(['ADMINISTRATOR', 'ADMIN', 'CUSTOMER'])),
  state: z.nativeEnum(CustomerState),
});

export type AdministratorDto = z.infer<typeof administratorSchema>;
