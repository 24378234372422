export class SortRule {
  constructor(
    public name: string,
    public column: string,
    public dbColumn: string,
    public sortOrder: 'ASC' | 'DESC' | '' = ''
  ) {}

  updateSortOrder(): void {
    switch (this.sortOrder) {
      case 'ASC':
        this.sortOrder = 'DESC';
        break;
      case 'DESC':
        this.sortOrder = '';
        break;
      default:
        this.sortOrder = 'ASC';
        break;
    }
  }

  toJSON() {
    return {
      column: this.dbColumn,
      sortOrder: this.sortOrder,
    };
  }
}
