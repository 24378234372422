import { z } from 'zod';

// Define the enum for OrderStatus using z.enum for regular JS objects
const OrderState = z.enum([
  'UNKNOWN',
  'PAID',
  'PENDING',
  'OPEN',
  'PAYMENT_FAILED',
  'PAYMENT_REQUIRED',
  'CONFIRMED',
  'SHIPPED',
  'DELIVERED',
  'CANCELED',
  'EXPIRED',
  'BLOCKED',
  'SETTLED',
  'CHARGEBACK',
  'REFUNDED',
  'REFUND_PROCESSING',
  'PARTIALLY_REFUNDED',
  'AUTHORISED',
]);

const userState = z.enum(['BLOCKED', 'ACTIVE', 'TO_ACTIVATE', 'INACTIVE']);

// Schema for the user details
const userDetailsSchema = z
  .object({
    name: z.string(),
    email: z.string().email(),
    state: userState,
  })
  .optional();

// Schema for the delivery details
const deliveryDetailsSchema = z.object({
  delivery_method: z.string(),
  street: z.string().nullish(),
  number: z.string().nullish(),
  postal_code: z.string().nullish(),
  city: z.string().nullish(),
  country: z.string().nullish(),
});

// Schema for the delivery details
const invoiceDetailsSchema = z.object({
  type: z.enum(['business', 'private']),
  street: z.string(),
  number: z.string(),
  postal_code: z.string(),
  city: z.string(),
  country: z.string(),
});

// Schema for the ordered items attributes
const itemAttributesSchema = z
  .object({
    color: z.string(),
    depth: z.string(),
    finish: z.string(),
    height: z.string(),
    width: z.string(),
  })
  .or(z.object({}));

// Schema for each ordered item
const orderedItemSchema = z.object({
  order_id: z.string().uuid(),
  product_id: z.string().uuid(),
  variant_id: z.string().uuid(),
  type: z.enum(['TABLE', 'STANDALONE']).default('TABLE'),
  attributes: itemAttributesSchema,
  created_at: z.string().datetime({ offset: true }),
  delivery_time: z.string(),
  image_to_display: z.string(),
  price_inc_vat: z.number(),
  quantity: z.number().int(),
  vat: z.number(),
});

const orderHistorySchema = z.object({
  from: OrderState.nullish(),
  to: OrderState,
  created_at: z.string().datetime({ offset: true }),
});

// Main order schema
export const orderDetailsSchema = z.object({
  id: z.string().uuid(),
  created_at: z.string().datetime({ offset: true }), // Use .datetime() with correct configuration if necessary
  user_id: z.string().uuid(), // Validate UUID format
  invoice_id: z.string(),
  total_inc_vat: z.number(),
  state: OrderState,
  payment_intent_id: z.string().optional().nullable(), // Optional and can be null

  invoice_details: invoiceDetailsSchema,
  user_details: userDetailsSchema,
  delivery_details: deliveryDetailsSchema,
  ordered_items: z.array(orderedItemSchema),
  order_history: z.array(orderHistorySchema),
  linked_tutorials: z.array(z.any()), // Assuming tutorials can be any type, adjust as needed
  mollie_id: z.string(),
});

// Type inference to use in TypeScript for type safety
export type OrderDetailsDto = z.infer<typeof orderDetailsSchema>;
