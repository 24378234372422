import { z } from 'zod';

export const tutorialSchema = z.object({
  name: z.string(),
  description: z.string(),
  id: z.string(),
  video_url: z.string().optional(),
  isActive: z.boolean().default(true),
  isPublic: z.boolean().default(true),
});

export const tutorialsSchema = z.array(tutorialSchema);

export type Tutorial = z.infer<typeof tutorialSchema>;
