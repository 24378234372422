import { SortRule } from '../model/SortRule';

import SortHeader from './SortHeader';

type SortRuleHeaderProps = {
  sortRules: SortRule[];
  column: string;
  updateSortOrder: (sortRule: SortRule) => void;
}


const SortRuleHeader = ({ sortRules, column, updateSortOrder }: SortRuleHeaderProps) => {
  const sortRule = sortRules.find((rule) => rule.column === column);

  if (!sortRule) {
    return <span>{column}</span>
  }

  return (
    <div
      className="flex items-center gap-4"
      onClick={() => updateSortOrder(sortRule)}
    >
      <span>{sortRule.name}</span>
      <SortHeader sortRule={sortRule} />
    </div>
  )
}

export default SortRuleHeader;
