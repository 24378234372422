import { X } from 'lucide-react';
import { useState } from 'react';

import { useModalStore } from '../../../stores/modalStore';
import { MODALS } from '../../constants';
import Modal from '../../elements/Modal';
import { BaseProduct, Product, RelatedProduct } from '../dto/product.dto';
import { useProducts } from '../hooks';


type AddRelatedProductModalProps = {
  product: Product;
  productId: string;
  editing: boolean;
  addRelatedProduct: (relatedProduct: RelatedProduct) => void;
};

const AddRelatedProductModal = ({ product, addRelatedProduct }: AddRelatedProductModalProps) => {
  const { isLoading, data } = useProducts();
  const [selectedProducts, setSelectedProducts] = useState<BaseProduct[]>([]);
  const modalStore = useModalStore();

  const close = () => {
    modalStore.closeModal(MODALS.ADD_RELATED_PRODUCTS);
  };

  const toggleProduct = (product: BaseProduct) => {
    if (selectedProducts.some(selectedProduct => selectedProduct.id === product.id)) {
      setSelectedProducts(selectedProducts.filter(selectedProduct => selectedProduct.id !== product.id))
      return
    }

    setSelectedProducts([...selectedProducts, product])
  };

  const addProducts = () => {
    selectedProducts.forEach(product => {
      addRelatedProduct({
        ...product,
        images: []
      })
    });

    setSelectedProducts([]);
    close();
  }

  const uniqueProducts = data?.filter(p => {
    return !product.related_products.some(relatedProduct => relatedProduct.id === p.id);
  });

  return (
    <Modal id={MODALS.ADD_RELATED_PRODUCTS}>
      <>
        <div className="relative pt-4">
          <h1 className="ml-8 text-text text-2xl font-bold">Selecteer de producten die gerelateerd zijn</h1>
          <X
            className="absolute right-0 top-0 text-text cursor-pointer"
            onClick={close}
          />
        </div>

        <div className="p-8 pb-0 w-full">
          <>
            {
              !isLoading && uniqueProducts?.length === 0 && (
                <div className="p-4 bg-primary">
                  <p>Er zijn geen producten meer om toe te voegen.</p>
                </div>
              )
            }
            <ul className="grid grid-cols-3 gap-4 rounded-lg">
              {
                !isLoading && uniqueProducts?.map(product => {

                  return product.variants.map(variant => (
                    <li
                      key={product.id}
                      className={
                        `bg-primary 
                        p-4 rounded-lg relative cursor-pointer 
                        bg-no-repeat bg-cover min-h-[200px] mb-4 
                        ${selectedProducts.some(selectedProduct => selectedProduct.id === product.id) ? 'border-2 border-accent border-solid' : ''
                        }`
                      }
                      style={{ gridTemplateRows: '40px 1fr 60px' }}
                      onClick={() => toggleProduct(product)}
                    >
                      <div className="w-full h-[200px] mb-4">
                        <img
                          className="w-full h-[200px] object-cover rounded-lg"
                          src={`/api/v1/variants/${variant.image_to_display}/image`}
                          alt=""
                        />
                      </div>

                      <span className="text-xl block font-semibold">
                        {product.name === '' ? '--' : product.name}
                      </span>

                      <div className="flex flex-col">
                        <span className="text block font-semibold mb-4">
                          € {(variant.price_inc_vat).toFixed(2)}
                        </span>

                        {
                          product.type === 'TABLE' && 'color' in variant.attributes && (
                            <>
                              <span>
                                hoogte: {variant.attributes.height}
                              </span>
                              <span>
                                breedte: {variant.attributes.width}
                              </span>
                              <span>
                                lengte: {variant.attributes.depth}
                              </span>
                              <span>
                                afwerking: {variant.attributes.finish}
                              </span>
                              <span className="flex gap-4">
                                kleur:
                                <div
                                  className="w-[20px] h-[20px] rounded-[50%] border-2 border-solid border-[#000]"
                                  style={{ backgroundColor: 'color' in variant.attributes ? variant.attributes.color : 'transparent' }}
                                />
                              </span>
                            </>
                          )
                        }

                        <span>
                          Levertermijn: {variant.delivery_time}
                        </span>
                      </div>
                    </li>
                  ))
                })
              }
            </ul>
            <button
              type="submit"
              className={`bg-accent text-primary p-4 w-full rounded-lg mt-4 hover:bg-accent-hover duration-300 transition-all ${selectedProducts.length === 0 ? 'opacity-50 cursor-not-allowed' : ''}`}
              disabled={selectedProducts.length === 0}
              onClick={addProducts}
            >
              Voeg producten toe
            </button>

            <button
              className="text-text text-center w-full mt-4 hover:text-accent"
              onClick={close}
            >
              Cancel
            </button>
          </>
        </div>




      </>
    </Modal>
  );
};

export default AddRelatedProductModal;

