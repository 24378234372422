import * as Dialog from '@radix-ui/react-dialog';
import { useEffect, useState } from 'react';

import { useModalStore } from '../../stores/modalStore';

interface props {
  id: string;
  className?: string;
  onClose?: () => void;
  onOpen?: () => void;
  disableFooter?: boolean;
  children?: JSX.Element | JSX.Element[];
}

const Modal = ({ id, onClose, onOpen, children }: props) => {
  const modalStore = useModalStore();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isOpen && onOpen !== undefined) {
      onOpen();
    }

    if (!isOpen && onClose !== undefined) {
      onClose();
    }
  }, [isOpen, onClose, onOpen]);

  useEffect(() => {
    if (!modalStore.isRegistered(id)) {
      modalStore.registerModal({
        id,
        cb: (isOpen: boolean) => {
          setIsOpen(isOpen);
        },
      });
    }

    return () => {
      modalStore.unregisterModal(id);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onOpenChange = (isOpen: boolean) => {
    setIsOpen(isOpen);
  };

  return (
    <>
      <Dialog.Root open={isOpen} onOpenChange={onOpenChange}>
        <Dialog.Portal>
          <Dialog.Overlay className="DialogOverlay" />
          <Dialog.Content className="DialogContent">{children}</Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  );
};

export default Modal;
