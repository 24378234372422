import { z } from 'zod';

import { productSchema } from './product.dto';

export const categorySchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  description: z.string().optional(),
  parentId: z.string().uuid().nullable(),
  products: z.array(productSchema).optional().default([]),
  subcategories: z
    .array(
      z.object({
        id: z.string().uuid(),
        name: z.string(),
        description: z.string().optional(),
        parentId: z.string().uuid().nullable(),
        products: z.array(productSchema).optional().default([]),
      })
    )
    .optional()
    .default([]),
});

export const breadcrumbSchema = z.array(
  z.object({
    id: z.string().uuid(),
    name: z.string(),
  })
);

export const categoriesSchema = z.array(categorySchema);

export const categoryDetailsSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  description: z.string().optional(),
  parent_id: z.string().uuid().optional(),
  products: z
    .array(
      z.object({
        id: z.string(),
        name: z.string(),
        description: z.string().optional(),
        variants: z.array(
          z.object({
            image_to_display: z.string(),
            id: z.string(),
          })
        ),
      })
    )
    .optional()
    .default([]),
  subcategories: z
    .array(
      z.object({
        id: z.string().uuid(),
        name: z.string(),
        description: z.string().optional(),
        parent_id: z.string().uuid().optional(),
      })
    )
    .optional()
    .default([]),
});

export type Breadcrumb = z.infer<typeof breadcrumbSchema>;
export type CategoryDto = z.infer<typeof categorySchema>;
export type CategoryDetailsDto = z.infer<typeof categoryDetailsSchema>;
