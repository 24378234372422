type BadgeProps = {
  text: string;
  color: string;
}

const Badge = ({ text, color }: BadgeProps) => {
  return (
    <div
      className="flex justify-center items-center gap-4 rounded-lg h-[30px] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[120px]"
      style={{ backgroundColor: color }}
    >
      <span
        className="p-4 text-sm"
        style={{
          color: color,
          filter: 'brightness(0.5)',
        }}
      >
        {text}
      </span>
    </div>
  )
}

export default Badge;

