import { ShoppingCart, Trash, Users } from 'lucide-react';
import { useMemo } from 'react';

import Button from '../elements/Button';
import VideoPlayer from '../tutorials/_videoPlayer';
import { useTutorials } from '../tutorials/hooks';

import { LinkedTutorial } from './dto/product.dto';

type LinkedTutorialsProps = {
  linkedTutorials: LinkedTutorial[]
  removeLinkedTutorial: (tutorial: LinkedTutorial) => void;
}

const LinkedTutorials = ({ linkedTutorials, removeLinkedTutorial }: LinkedTutorialsProps) => {
  const { data: tutorials } = useTutorials();

  const matchingTutorials = useMemo(() => {
    return tutorials?.filter(tutorial => linkedTutorials.some(linkedTutorial => linkedTutorial.id === tutorial.id))
  }, [linkedTutorials, tutorials])


  if (matchingTutorials?.length === 0) {
    return (
      <div className="p-4 bg-primary">
        <p>Momenteel zijn er nog geen tutorials gekoppeld aan dit product.</p>
      </div>
    )
  }

  return (
    <ul className="grid grid-cols-1 gap-5 md:grid-cols-2">
      {
        matchingTutorials?.map(tutorial => {
          return (
            <li
              className="relative rounded-lg bg-primary flex flex-col min-h-[150px] p-4"
              key={tutorial.id}
            >
              <div>
              </div>
              <div className="w-full h-[200px] bg-[#eee] rounded-2xl mb-4">
                {
                  tutorial.video_url && (
                    <VideoPlayer filePath={tutorial.video_url} />
                  )
                }
              </div>

              <div className="flex flex-col mb-4">
                <div className="flex gap-2 items-center mb-4 text-xs  text-[#8c8c8c]">

                  {
                    tutorial.isPublic && (
                      <>
                        <Users />
                        <span className="text-sm">Publiek toegankelijk</span>
                      </>
                    )
                  }

                  {
                    !tutorial.isPublic && (
                      <>
                        <ShoppingCart />
                        <span className="text-sm">Gekoppeld aan product</span>
                      </>
                    )
                  }

                  {
                    tutorial.isActive && (
                      <span className="text-sm">/ Actief</span>
                    )
                  }

                  {
                    !tutorial.isActive && (
                      <span className="text-sm">/ Inactief</span>
                    )
                  }

                </div>
                <span className="text-xl block font-semibold">{tutorial.name}</span>
                <span>{tutorial.description}</span>
              </div>


              <div className="flex justify-between gap-2">
                <Button
                  onClick={() => removeLinkedTutorial(tutorial)}
                  icon={<Trash />}
                  variant="delete"
                />
              </div>
            </li>
          )
        })

      }
    </ul>
  )
}

export default LinkedTutorials;
