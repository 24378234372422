type InputErrorProps = {
  text: string | undefined;
}

const InputError = ({ text }: InputErrorProps) => {
  return (
    <label className="text-error mt-2 block">{text}</label>
  )
}

export default InputError;

