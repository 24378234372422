import { Info, Lock, Save, Trash } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useModalStore } from '../../stores/modalStore';
import { useToastStore } from '../../stores/toastStore';
import ApiError from '../ApiError';
import { MODALS } from '../constants';
import { Button, Error } from '../elements';
import InputError from '../elements/InputError';

import { useAdministratorById, useEditAdministrator, useEditAdministratorForm, useSendPasswordReset } from './hooks';
import DeleteAdminModal from './modal/DeleteAdminModal';

type AdministratorsDetailsContainerProps = {
  administratorId: string;
};

const AdministratorsDetailsContainer = ({
  administratorId,
}: AdministratorsDetailsContainerProps) => {
  const modalStore = useModalStore();
  const { successToast, errorToast } = useToastStore();
  const { isLoading, data: admin } = useAdministratorById(administratorId);
  const [error, setError] = useState<string | null>(null);

  const { sendPasswordReset } = useSendPasswordReset();

  const {
    handleSubmit,
    register,
    reset,
    getValues,
    formState: { errors },
  } = useEditAdministratorForm({
    name: '',
    email: '',
    id: ''
  });

  const { updateAdministrator } = useEditAdministrator();

  useEffect(() => {
    if (admin !== null) {
      reset(admin)
    }
  }, [reset, admin]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (admin === undefined) {
    return <div>Administrator not found</div>;
  }

  const performUpdateAdmin = async () => {
    try {
      await updateAdministrator(getValues());
      successToast('Administrator gewijzigd')
      reset();
      close();
    } catch (err) {
      if (err instanceof ApiError && err.status === 409) {
        setError('Email is al in gebruik')
        return
      }

      errorToast('Er is iets misgegaan bij het wijzigen van de administrator')
      console.log(err);
    }

    setError(null);
  };

  const performResetPassword = async () => {
    try {
      await sendPasswordReset(admin.email);
      successToast('Wachtwoord reset email verzonden')
    } catch (err) {
      errorToast('Er is iets misgegaan bij het verzenden van de wachtwoord reset email')
      console.log(err);
    }
  };

  const openDeleteAdmin = () => {
    modalStore.openModal(MODALS.DELETE_ADMIN);
  }

  return (
    <>
      <h1 className="font-bold mb-8">{admin.name}</h1>

      <div className="grid mb-4 gap-4 grid-cols-1 md:grid-cols-2">
        <div className="bg-lighter p-4 rounded-lg">
          <div className="flex justify-start items-center mb-8 gap-4">
            <Info />
            <h2 className="text-xl font-bold">
              Administrator gegevens
            </h2>
          </div>

          {
            error && (
              <Error
                error={error}
              />
            )
          }

          <form onSubmit={handleSubmit(performUpdateAdmin)}>
            <div className="flex flex-col mb-4">
              <label htmlFor="name" className="mb-1 font-semibold">
                Name
              </label>
              <input
                {...register('name')}
                placeholder="John Doe"
                className="p-4 bg-primary rounded-lg"
              />

              {
                errors?.name !== undefined && (
                  <InputError
                    text={errors.name.message}
                  />
                )
              }
            </div>
            <div className="flex flex-col mb-4">
              <label htmlFor="email" className="mb-1 font-semibold">
                Email
              </label>
              <input
                {...register('email')}
                placeholder="customer@diy-atelier.be"
                className="p-4 bg-primary rounded-lg"
              />

              {
                errors?.email !== undefined && (
                  <InputError
                    text={errors.email.message}
                  />
                )
              }
            </div>

            <Button
              type="submit"
              text="Bewaar wijzigingen"
              icon={<Save />}
              variant="primary"
              style={{ justifyContent: 'center' }}
              fullWidth
            />
          </form>
        </div>

        <div className="bg-lighter p-4 rounded-lg">
          <div className="flex justify-start items-center mb-8 gap-4">
            <Info />
            <h2 className="text-xl font-bold">Snelle acties</h2>
          </div>

          <div className="flex flex-col gap-4">
            <Button
              onClick={openDeleteAdmin}
              variant="delete"
              fullWidth
              style={{ justifyContent: 'center' }}
              text="Verwijder administrator"
              icon={<Trash />}
            />

            <Button
              onClick={performResetPassword}
              variant="delete"
              fullWidth
              style={{ justifyContent: 'center' }}
              text="Reset wachtwoord"
              icon={<Lock />}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const AdministratorDetailsPage = () => {
  const { administratorId } = useParams();

  return (
    <>
      <main className="p-16 relative min-h-[calc(100vh-120px)]">
        {administratorId && (
          <>
            <DeleteAdminModal adminId={administratorId} />
            <AdministratorsDetailsContainer administratorId={administratorId} />
          </>
        )}
      </main>
    </>
  );
};

export default AdministratorDetailsPage;
