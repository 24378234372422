import { z } from 'zod';

export const addAdministratorSchema = z.object({
  name: z.string().min(2, 'Vul een geldige naam in'),
  email: z
    .string()
    .min(2, 'Vul een geldig e-mailadres in')
    .email('Vul een geldig e-mailadres in'),
});

export type AddAdministratorDto = z.infer<typeof addAdministratorSchema>;
