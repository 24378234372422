import { ButtonHTMLAttributes } from 'react';

type ButtonProps = {
  text?: string;
  variant?: 'primary' | 'secondary' | 'tertiary' | 'delete';
  icon?: JSX.Element;
  className?: string;
  fullWidth?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const Button = ({
  text,
  icon,
  variant,
  className,
  fullWidth,
  ...rest
}: ButtonProps) => {
  let colors = '';

  switch (variant) {
    case 'primary':
      colors = 'bg-accent text-primary hover:bg-accent-hover';
      break;
    case 'secondary':
      colors =
        'bg-lighter text-accent hover:bg-accent-hover hover:text-lighter';
      break;
    case 'delete':
      colors = 'bg-error text-primary hover:bg-accent-hover hover:text-lighter';
      break;

    case 'tertiary':
      colors =
        'bg-light-accent text-accent hover:bg-accent-hover hover:text-lighter';
  }

  return (
    <button
      className={` p-4 ${colors} flex justify-between rounded-lg gap-4 ${className} w-full ${fullWidth ? 'w-full md:w-full sm:w-full' : undefined}`}
      {...rest}
    >
      {text && <span>{text}</span>}
      {icon && icon}
    </button>
  );
};

export default Button;
