import { ColDef } from 'ag-grid-community';
import { useNavigate } from 'react-router-dom';

import Badge from '../elements/Badge';
import SortRuleHeader from '../elements/SortRuleHeader';
import SimpleGrid from '../elements/Table';
import { SortRule } from '../model/SortRule';

import { CustomerDto } from './dto/customer.dto';

type UsersGrid = {
  data: CustomerDto[]
  onSortRulesUpdate: (sortRule: SortRule) => void
  sortRules: SortRule[]
  type: 'customers' | 'administrators'
};

const UsersGrid = ({ data, onSortRulesUpdate, sortRules, type }: UsersGrid) => {
  const navigate = useNavigate();

  const stateColorMap: Record<string, string> = {
    BLOCKED: '#ffbbbb',
    ACTIVE: '#b1e9c8',
    TO_ACTIVATE: '#ffad5d',
    INACTIVE: '#eee',
  };

  const stateTranslationMap: Record<string, string> = {
    BLOCKED: 'Geblokkeerd',
    ACTIVE: 'Actief',
    TO_ACTIVATE: 'Te activeren',
    INACTIVE: 'Inactief',
  };

  const goToUserDetails = (userId: string) => {
    if (type === 'administrators') {
      navigate(`/administrators/${userId}`);
    } else {
      navigate(`/klanten/${userId}`);
    }
  };

  const updateSortOrder = (sortRule: SortRule) => {
    sortRule.updateSortOrder();
    onSortRulesUpdate(sortRule);
  };


  const columnDefs: ColDef[] = [
    {
      headerName: 'Status',
      field: 'state',
      filter: false,
      sortable: false,
      minWidth: 140,
      flex: 1,
      headerComponent: () => {
        return (
          <SortRuleHeader
            sortRules={sortRules}
            column="state"
            updateSortOrder={updateSortOrder}
          />
        )
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      cellRenderer: (params: any) => {
        return (
          <Badge
            color={stateColorMap[params.value]}
            text={stateTranslationMap[params.value]}
          />
        );
      },
    },
    {
      headerName: 'Naam',
      field: 'name',
      filter: false,
      sortable: false,
      minWidth: 140,
      flex: 1,
      headerComponent: () => {
        return (
          <SortRuleHeader
            sortRules={sortRules}
            column="name"
            updateSortOrder={updateSortOrder}
          />
        )
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      cellRenderer: (params: any) => {
        return params.value === '' ? '--' : params.value;
      },
    },
    {
      headerName: 'Email',
      field: 'email',
      filter: false,
      sortable: false,
      flex: 1,
      minWidth: 140,
      headerComponent: () => {
        return (
          <SortRuleHeader
            sortRules={sortRules}
            column="email"
            updateSortOrder={updateSortOrder}
          />
        )
      },
    },
    {
      headerName: 'Aangemaakt op',
      field: 'createdAt',
      filter: false,
      minWidth: 200,
      sortable: false,
      flex: 1,
      headerComponent: () => {
        return (
          <SortRuleHeader
            sortRules={sortRules}
            column="created_at"
            updateSortOrder={updateSortOrder}
          />
        )
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      cellRenderer: (params: any) => {
        return new Date(params.value).toLocaleDateString();
      },
    },
  ];

  return (
    <SimpleGrid
      columnDefs={columnDefs}
      rowData={data}
      onRowClick={(params) => goToUserDetails(params.data.id)}
    />
  )
}

export default UsersGrid;
