import { z } from 'zod';

export const updateCategorySchema = z.object({
  id: z.string({
    required_error: 'ID is verplicht.',
    invalid_type_error: 'ID moet een string zijn.'
  }),
  name: z.string()
    .min(2, { message: 'Naam moet minimaal 2 tekens bevatten.' }),
  description: z.string()
    .min(2, { message: 'Beschrijving moet minimaal 2 tekens bevatten.' }),
  parentId: z.string()
    .uuid({ message: 'Ongeldig UUID-formaat voor ouder-ID.' }),
  imageSrc: z.string().optional(),
});

export type UpdateCategoryDto = z.infer<typeof updateCategorySchema>;
