import { Trash, X } from 'lucide-react';

import { useModalStore } from '../../../stores/modalStore';
import { useToastStore } from '../../../stores/toastStore';
import ApiError from '../../ApiError';
import { MODALS } from '../../constants';
import { Button } from '../../elements';
import Modal from '../../elements/Modal';
import { useDeleteTutorial } from '../hooks';

type DeleteTutorialModalProps = {
  tutorialId?: string;
};

const DeleteTutorialModal = ({
  tutorialId,
}: DeleteTutorialModalProps) => {
  const modalStore = useModalStore();
  const { successToast, errorToast } = useToastStore();
  const { isPending, deleteTutorial } = useDeleteTutorial();

  const close = () => {
    modalStore.closeModal(MODALS.DELETE_TUTORIAL);
  };

  const confirmDelete = async () => {
    if (!tutorialId) {
      return errorToast('Er is iets misgegaan tijdens het verwijderen van de tutorial');

    }

    try {
      await deleteTutorial(tutorialId);
      close();
      successToast('Tutorial verwijderd');
    } catch (err) {
      if (err instanceof ApiError) {
        if (err.status === 403) {
          errorToast('Deze tutorial kan niet verwijderd worden, omdat deze in gebruik is')
        }
        close();
      } else {
        errorToast('Er is iets misgegaan tijdens het verwijderen van de tutorial')
        console.log(err);
      }
    }
  };

  return (
    <Modal id={MODALS.DELETE_TUTORIAL}>
      <>
        <div className="relative pt-4">
          <h1 className="ml-8 text-text text-2xl">Verwijder tutorial</h1>
          <X
            className="absolute right-0 top-0 text-text cursor-pointer"
            onClick={close}
          />
        </div>

        <div className="p-8 pb-0 w-full">
          <p className="p-4 bg-primary mb-4">
            Ben je zeker dat je deze tutorial wenst te verwijderen? Deze actie
            kan niet ongedaan gemaakt worden.
          </p>
          <Button
            onClick={confirmDelete}
            disabled={isPending}
            text="Ja, verwijder tutorial"
            icon={<Trash />}
            variant="delete"
            fullWidth
            className="mb-4"
            style={{ justifyContent: 'center' }}
          />

          <Button
            onClick={close}
            fullWidth
            text="Nee, ga terug"
            variant="tertiary"
            style={{ justifyContent: 'center' }}
          />
        </div>
      </>
    </Modal>
  );
};

export default DeleteTutorialModal;
