import { useState } from 'react'
import Cropper, { Area, Point } from 'react-easy-crop'

const createImage = (imageSrc: string): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', (error) => reject(error))
    image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
    image.src = imageSrc //base64
  })
}


const getCroppedImg = async (
  imageSrc: null | string,
  pixelCrop: Area
) => {
  if (!imageSrc) {
    return null
  }

  const image = await createImage(imageSrc)
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  if (!ctx) {
    return null
  }


  // set canvas size to match the bounding box
  canvas.width = image.width
  canvas.height = image.height

  ctx.drawImage(image, 0, 0);


  const croppedCanvas = document.createElement('canvas')
  const croppedCtx = croppedCanvas.getContext('2d')

  if (!croppedCtx) {
    return null
  }

  // Set the size of the cropped canvas
  croppedCanvas.width = pixelCrop.width
  croppedCanvas.height = pixelCrop.height

  // Draw the cropped image onto the new canvas
  croppedCtx.drawImage(
    canvas,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height
  )

  // As Base64 string
  return croppedCanvas.toDataURL('image/jpeg');
}


type CropperProps = {
  imgSrc: string;
  onCropComplete: (imageSrc: string) => void;
}

const CropperWrapper = ({ imgSrc, onCropComplete: onComplete }: CropperProps) => {
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  const onCropComplete = async (_: Area, croppedAreaPixels: Area) => {
    // TODO: add a debounce here
    const croppedImg = await getCroppedImg(imgSrc, croppedAreaPixels);
    if (croppedImg) {
      onComplete(croppedImg);
    }
  };

  return (
    <Cropper
      style={{ containerStyle: { borderRadius: '0.5rem' } }}
      image={imgSrc}
      crop={crop}
      zoom={zoom}
      aspect={4 / 3}
      onCropChange={setCrop}
      onCropComplete={onCropComplete}
      onZoomChange={setZoom}
    />
  )
}

export default CropperWrapper;
