import { Trash, X } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

import { useModalStore } from '../../../stores/modalStore';
import { useToastStore } from '../../../stores/toastStore';
import { MODALS } from '../../constants';
import { Button } from '../../elements';
import Modal from '../../elements/Modal';
import { useDeleteCategory } from '../hooks';



type DeleteCategoryModalProps = {
  categoryId: string;
  parentId: string;
};

const DeleteCategoryModal = ({
  categoryId,
  parentId,
}: DeleteCategoryModalProps) => {
  const navigate = useNavigate();
  const modalStore = useModalStore();
  const { successToast, errorToast } = useToastStore();
  const { isPending, isError, error, deleteCategory } = useDeleteCategory();

  const close = () => {
    modalStore.closeModal(MODALS.DELETE_CATEGORY);
  };

  const confirmDelete = async () => {
    try {
      await deleteCategory(categoryId);
      close();
      navigate(`/catalogus/${parentId}`);
      successToast('Categorie verwijderd');
    } catch (err) {
      errorToast('Er is iets misgegaan tijdens het verwijderen van de categorie')
      console.log(err);
    }
  };

  return (
    <Modal id={MODALS.DELETE_CATEGORY}>
      <>
        <div className="relative pt-4">
          <h1 className="ml-8 text-text text-2xl">Verwijder categorie</h1>
          <X
            className="absolute right-0 top-0 text-text cursor-pointer"
            onClick={close}
          />
        </div>

        {isError && error !== null && <div>{error.message}</div>}

        <div className="p-8 pb-0 w-full">
          <p className="p-4 bg-primary mb-4">
            Ben je zeker dat je deze categorie wenst te verwijderen? Deze actie
            kan niet ongedaan gemaakt worden.
          </p>
          <Button
            onClick={confirmDelete}
            disabled={isPending}
            text="Ja, verwijder categorie"
            icon={<Trash />}
            variant="delete"
            fullWidth
            className="mb-4"
            style={{ justifyContent: 'center' }}
          />

          <Button
            onClick={close}
            fullWidth
            text="Nee, ga terug"
            variant="tertiary"
            style={{ justifyContent: 'center' }}
          />
        </div>
      </>
    </Modal>
  );
};

export default DeleteCategoryModal;
