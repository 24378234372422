import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';

import ApiError from '../ApiError';
import { QUERY_KEYS } from '../constants';
import { doFetch, uploadFileFetch } from '../utils';

import { AddTutorialDto, addTutorialSchema } from './dto/add-tutorial.dto';
import { EditTutorialDto, editTutorialSchema } from './dto/edit-tutorial.dto';
import { tutorialSchema, tutorialsSchema } from './dto/tutorials.dto';

const URLS = {
  GET_TUTORIALS: '/api/v1/tutorials',
  LINK_VIDEO: (tutorialId: string) => `/api/v1/tutorials/${tutorialId}/video`,
  DELETE_TUTORIAL: (tutorialId: string) => `/api/v1/tutorials/${tutorialId}`,
  UPDATE_TUTORIAL: (tutorialId: string) => `/api/v1/tutorials/${tutorialId}`,
  ADD_TUTORIAL: '/api/v1/tutorials',
};

export const useAddTutorialForm = (addTutorialDto: AddTutorialDto) => {
  return useForm<AddTutorialDto>({
    defaultValues: addTutorialDto,
    resolver: zodResolver(addTutorialSchema),
  });
};

export const useEditTutorialForm = (editTutorialDto: EditTutorialDto) => {
  return useForm<EditTutorialDto>({
    defaultValues: editTutorialDto,
    resolver: zodResolver(editTutorialSchema),
  });
};

export const useTutorials = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.tutorials],
    queryFn: async () => {
      const { status, data } = await doFetch(URLS.GET_TUTORIALS, 'GET');
      if (status !== 200) {
        throw new Error(data.message);
      }

      return tutorialsSchema.parse(data);
    },
  });
};

export const useLinkVideo = () => {
  const queryClient = useQueryClient();
  const { isPending, isError, mutate, error } = useMutation({
    mutationFn: async ({
      files,
      tutorialId,
    }: {
      files: File[];
      tutorialId: string;
    }) => {
      const url = URLS.LINK_VIDEO(tutorialId);
      const { status } = await uploadFileFetch(url, 'POST', files);

      if (status !== 201) {
        throw new Error('An error occurred while uploading images');
      }

      return { status };
    },
  });

  const linkVideo = async (tutorialId: string, files: File[]) => {
    return new Promise((resolve, reject) => {
      mutate(
        { files, tutorialId },
        {
          onSuccess: (data) => {
            queryClient.invalidateQueries({
              queryKey: [QUERY_KEYS.tutorials],
            });
            resolve(data);
          },
          onError: reject,
        }
      );
    });
  };

  return {
    isPending,
    isError,
    linkVideo,
    error,
  };
};

export const useDeleteTutorial = () => {
  const queryClient = useQueryClient();

  const { isPending, isError, mutate, error } = useMutation({
    mutationFn: async ({ id }: { id: string }) => {
      const { status } = await doFetch(URLS.DELETE_TUTORIAL(id), 'DELETE');

      if (status !== 200) {
        throw new ApiError(
          'An error occurred while deleting the tutorial',
          status
        );
      }

      return { status };
    },
  });

  const deleteTutorial = async (tutorialId: string) => {
    return new Promise((resolve, reject) => {
      mutate(
        { id: tutorialId },
        {
          onSuccess: (data) => {
            queryClient.invalidateQueries({
              queryKey: [QUERY_KEYS.tutorials], // Assuming there's a query key for products
            });
            resolve(data);
          },
          onError: reject,
        }
      );
    });
  };

  return {
    isPending,
    isError,
    deleteTutorial,
    error,
  };
};

export const useAddTutorial = () => {
  const queryClient = useQueryClient();
  const { isPending, isError, mutate, error } = useMutation({
    mutationFn: async ({
      addTutorialDto,
      videoSrc,
    }: {
      addTutorialDto: AddTutorialDto;
      videoSrc: File;
    }) => {
      const { name, description } = addTutorialDto;

      // first create the tutorial
      const { status, data } = await doFetch(URLS.ADD_TUTORIAL, 'POST', {
        name,
        description,
      });

      if (status !== 201) {
        throw new Error(data.message);
      }

      const tutorial = tutorialSchema.parse(data);

      // now link the video to the tutorial
      const { status: fileStatus } = await uploadFileFetch(
        URLS.LINK_VIDEO(tutorial.id),
        'PUT',
        [videoSrc]
      );

      if (fileStatus !== 200) {
        throw new Error(data.message);
      }

      return data;
    },
  });

  const addTutorial = async (
    addTutorialDto: AddTutorialDto,
    videoSrc: File
  ): Promise<AddTutorialDto> => {
    return new Promise((resolve, reject) => {
      mutate(
        { addTutorialDto, videoSrc },
        {
          onSuccess: () => {
            queryClient.invalidateQueries({
              queryKey: [QUERY_KEYS.tutorials],
            });
            resolve(addTutorialDto);
          },
          onError: reject,
        }
      );
    });
  };

  return {
    isPending,
    isError,
    addTutorial,
    error,
  };
};

export const useEditTutorial = () => {
  const queryClient = useQueryClient();
  const { isPending, isError, mutate, error } = useMutation({
    mutationFn: async ({
      editTutorialDto,
      videoSrc,
    }: {
      editTutorialDto: EditTutorialDto;
      videoSrc: File | null;
    }) => {
      const { name, description, id, isPublic, isActive } = editTutorialDto;

      // first create the tutorial
      const { status, data } = await doFetch(URLS.UPDATE_TUTORIAL(id), 'PUT', {
        name,
        description,
        isActive,
        isPublic,
      });

      if (status !== 200) {
        throw new Error(data.message);
      }

      if (!videoSrc) {
        return data;
      }

      const { status: fileStatus } = await uploadFileFetch(
        URLS.LINK_VIDEO(id),
        'PUT',
        [videoSrc]
      );

      if (fileStatus !== 200) {
        throw new Error(data.message);
      }

      return data;
    },
  });

  const editTutorial = async (
    editTutorialDto: EditTutorialDto,
    videoSrc: File | null
  ): Promise<EditTutorialDto> => {
    return new Promise((resolve, reject) => {
      mutate(
        { editTutorialDto, videoSrc },
        {
          onSuccess: () => {
            queryClient.invalidateQueries({
              queryKey: [QUERY_KEYS.tutorials],
            });
            resolve(editTutorialDto);
          },
          onError: reject,
        }
      );
    });
  };

  return {
    isPending,
    isError,
    editTutorial,
    error,
  };
};
