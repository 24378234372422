import { z } from 'zod';

import { orderSchema } from '../../orders/dto/order.dto';

export const dashboardStatsSchema = z.object({
  customer_states: z.object({
    BLOCKED: z.number(),
    ACTIVE: z.number(),
    TO_ACTIVATE: z.number(),
  }),
  order_stats: z.array(
    z.object({
      month: z.string(),
      revenue: z.number(),
      amount_of_orders: z.number(),
    })
  ),
  recent_orders: z.array(orderSchema),
});

export type DashboardStatsDto = z.infer<typeof dashboardStatsSchema>;
