import { useState } from 'react';

import { SortRule } from '../model/SortRule';
import useSortRules from '../pagination/hooks';
import { Pagination } from '../partials';

import { usePaginatedOrders } from './hooks';
import OrdersGrid from './OrdersGrid';


const OrdersPage = () => {
  const itemsPerPage = 10;
  const [searchFilter, setSearchFilter] = useState('');
  const [page, setPage] = useState(1);
  const { sortRules, updateSortRule } = useSortRules([
    new SortRule('Status', 'state', 'o.state'),
    new SortRule('Prijs', 'price', 'o.total_inc_vat'),
    new SortRule('Email', 'email', 'c.email'),
    new SortRule('Aangemaakt op', 'created_at', 'o.created_at'),
  ]);

  const { data, isError, error } = usePaginatedOrders(
    page,
    itemsPerPage,
    searchFilter,
    sortRules
  );

  const rowData = data?.orders ?? [];

  if (isError) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      <main
        className="p-4 md:p-16"
      >
        <div className="flex gap-4 items-center mb-4 justify-between">
          <h1 className="font-bold">Orders</h1>
        </div>

        <div className="flex gap-4">
          <input
            className="w-full p-4 mb-4 rounded-lg"
            type="email"
            placeholder="Zoek op datum, status, prijs ..."
            value={searchFilter}
            onChange={(ev) => setSearchFilter(ev.target.value)}
          />

          <Pagination
            className=""
            totalItems={data?.pagination.totalItems ?? 10}
            itemsPerPage={itemsPerPage}
            currentPage={page}
            onPageChange={setPage}
          />
        </div>

        <OrdersGrid
          data={rowData}
          onSortRulesUpdate={updateSortRule}
          sortRules={sortRules}
        />

      </main>
    </>
  );
};

export default OrdersPage;

