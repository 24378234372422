import {
  ChevronRight,
  Edit,
  Home,
  MoveRight,
  Plus,
  Trash,
} from 'lucide-react';
import { useNavigate, useParams } from 'react-router-dom';

import placeholderImage from '../../assets/images/placeholder.webp';
import { useModalStore } from '../../stores/modalStore';
import { MODALS } from '../constants';
import { Button, Placeholder } from '../elements';
import { Loading } from '../partials';

import { useBreadCrumbs, useCategoryById } from './hooks';
import AddCategoryModal from './modal/AddCategoryModal';
import DeleteCategoryModal from './modal/DeleteCategoryModal';
import EditCategoryModal from './modal/EditCategoryModal';

type CategroryDetailContainerProps = {
  categoryId: string;
};

const CategoryDetailContainer = ({
  categoryId,
}: CategroryDetailContainerProps) => {
  const navigate = useNavigate();
  const modalStore = useModalStore();

  const { data, isLoading, isError, error } = useCategoryById(categoryId);
  const { data: breadcrumbs } = useBreadCrumbs(categoryId);

  const openAddProduct = () => {
    navigate(`/categorie/${categoryId}/nieuw-product`);
  };

  const openAddCategory = () => {
    modalStore.openModal(MODALS.ADD_CATEGORY);
  };

  const openDeleteCategory = () => {
    modalStore.openModal(MODALS.DELETE_CATEGORY);
  };

  const openEditCategory = () => {
    modalStore.openModal(MODALS.EDIT_CATEGORY);
  }

  const goToProduct = (productId: string) => {
    navigate(`/product/${productId}`);
  };

  const goToCategory = (categoryId: string) => {
    navigate(`/catalogus/${categoryId}`);
  };

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <p>{error.message}</p>;
  }

  if (data === undefined) {
    return <p>Geen data gevonden</p>;
  }

  return (
    <>
      <AddCategoryModal categoryId={categoryId} />
      <EditCategoryModal
        category={data}
      />
      <DeleteCategoryModal
        categoryId={categoryId}
        parentId={data.parent_id ?? '00000000-0000-0000-0000-000000000000'}
      />

      <div className="flex-col items-start flex gap-4 xl:flex-row xl:items-center mb-16 xl:justify-between">
        <h1 className="font-bold mb-0">{data.name}</h1>

        <div className="flex flex-col gap-4 md:flex-row sm:w-max w-full">
          <Button
            onClick={openAddCategory}
            variant="primary"
            text="voeg categorie toe"
            icon={<Plus />}
          />

          <Button
            onClick={openAddProduct}
            variant="primary"
            text="voeg product toe"
            icon={<Plus />}
          />

          {
            categoryId !== '00000000-0000-0000-0000-000000000000' && (
              <>
                <Button
                  onClick={openEditCategory}
                  variant="primary"
                  text="Wijzig categorie"
                  icon={<Edit />}
                />

                <Button
                  onClick={openDeleteCategory}
                  variant="delete"
                  text="Verwijder categorie"
                  icon={<Trash />}
                />
              </>
            )
          }

        </div>
      </div>

      {breadcrumbs && (
        <ul className="flex mb-8">
          {breadcrumbs?.map((breadcrumb, idx) => (
            <li
              className="flex p-4 rounded-lg cursor-pointer hover:bg-[#fff]"
              key={breadcrumb.id}
              onClick={() => goToCategory(breadcrumb.id)}
            >
              {breadcrumb.id === '00000000-0000-0000-0000-000000000000' ? (
                <Home />
              ) : (
                breadcrumb.name
              )}

              {idx !== breadcrumbs.length + 1 && <ChevronRight />}
            </li>
          ))}
        </ul>
      )}



      {data.products.length === 0 && data.subcategories.length === 0 && (
        <Placeholder text="Geen producten of categorieen gevonden" />
      )}

      <ul className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4">
        {data.subcategories.map((category) => (
          <li
            key={category.id}
            className="bg-lighter p-4 rounded-lg relative cursor-pointer bg-no-repeat bg-cover min-h-[300px]  pb-[80px]"
          >
            <div className="w-full h-[250px] mb-4">
              <img
                className="w-full h-[250px] object-cover rounded-lg"
                src={`/api/v1/categories/${category.id}/image`}
                alt=""
              />
            </div>

            <span className="text-xl block font-semibold">
              {category.name === '' ? '--' : category.name}
            </span>
            <span className="mb-4 mt-4 block">
              {category.description === '' ? '--' : category.description}
            </span>

            <div
              className="absolute bottom-4 left-4"
              style={{ width: 'calc(100% - 2rem)' }}
            >
              <Button
                onClick={() => goToCategory(category.id)}
                variant="tertiary"
                text="Beheer categorie"
                icon={<MoveRight />}
                fullWidth
              />
            </div>
          </li>
        ))}

        {data.products.map((product) => {
          let imageSrc = placeholderImage

          if (product.variants.length > 0) {
            imageSrc = `/api/v1/variants/${product.variants[0].image_to_display}/image`
          }

          return (
            <li
              key={product.id}
              className="bg-lighter p-4 rounded-lg relative cursor-pointer bg-no-repeat bg-cover min-h-[300px] pb-[80px]"
              style={{ gridTemplateRows: '40px 1fr 60px' }}
            >
              <div className="w-full h-[250px] mb-4">
                <img
                  className="w-full h-[250px] object-cover rounded-lg"
                  src={imageSrc}
                  alt=""
                />
              </div>
              <span className="text-xl block font-semibold">
                {product.name === '' ? '--' : product.name}
              </span>
              <span className="mb-4 mt-4 block">
                {product.description === '' ? '--' : product.description}
              </span>


              <div
                className="absolute bottom-4 left-4"
                style={{ width: 'calc(100% - 2rem)' }}
              >
                <Button
                  onClick={() => goToProduct(product.id)}
                  variant="tertiary"
                  text="Beheer product"
                  icon={<MoveRight />}
                  fullWidth
                />
              </div>
            </li>
          )
        })}
      </ul>
    </>
  );
};

const CategoryDetailPage = () => {
  const { categoryId } = useParams();

  const nilUUID = '00000000-0000-0000-0000-000000000000';

  return (
    <main className="p-4 md:p-16 relative min-h-[calc(100vh-120px)]">
      <CategoryDetailContainer categoryId={categoryId ?? nilUUID} />
    </main>
  );
};

export default CategoryDetailPage;
