import { useState } from 'react';

import { SortRule } from '../model/SortRule';

const useSortRules = (initialRules: SortRule[]) => {
  const [sortRules, setSortRules] = useState(initialRules);

  const updateSortRule = (sortRule: SortRule): void => {
    const updatedSortRules = sortRules.map((rule) => {
      if (rule.column === sortRule.column) {
        return new SortRule(
          rule.name,
          rule.column,
          rule.dbColumn,
          rule.sortOrder
        );
      }

      return rule;
    });

    setSortRules([...updatedSortRules]);
  };

  return {
    sortRules,
    updateSortRule,
  };
};

export default useSortRules;
