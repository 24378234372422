import { create } from 'zustand';

interface Modal {
  id: string;
  cb: (isOpen: boolean) => void;
}

interface State {
  registeredModals: Modal[];
  activeModals: Modal[];
  isModalOpen: boolean;
}

interface Store extends State {
  registerModal: (modal: Modal) => void;
  unregisterModal: (id: string) => void;
  openModal: (id: string) => void;
  closeModal: (id: string) => void;
  closeModalWithOverlay: () => void;
  isRegistered: (id: string) => boolean;
}

export const useModalStore = create<Store>((set, get) => ({
  registeredModals: [],
  activeModals: [],
  isModalOpen: false,

  registerModal: (modal) =>
    set((state) => ({ registeredModals: [...state.registeredModals, modal] })),

  unregisterModal: (id) =>
    set((state) => ({
      registeredModals: state.registeredModals.filter(
        (modal) => modal.id !== id,
      ),
    })),

  openModal: (id) => {
    set((state) => {
      const match = state.registeredModals.find((modal) => modal.id === id);
      if (match) {
        match.cb(true);
        return {
          activeModals: [...state.activeModals, match],
          isModalOpen: true,
        };
      }
      return state;
    });
  },

  closeModal: (id) => {
    set((state) => {
      const match = state.registeredModals.find((modal) => modal.id === id);
      if (match) {
        match.cb(false);
        return {
          activeModals: state.activeModals.filter(
            (activeModal) => activeModal.id !== id,
          ),
          isModalOpen: state.activeModals.length > 1,
        };
      }
      return state;
    });
  },

  closeModalWithOverlay: () => {
    set((state) => {
      if (state.activeModals.length > 0) {
        state.activeModals.forEach((modal) => {
          modal.cb(false);
        });
        return { isModalOpen: false };
      }
      return state;
    });
  },

  isRegistered: (id) => {
    return get().registeredModals.some((modal) => modal.id === id);
  },
}));
