import { X } from 'lucide-react';
import { useMemo, useState } from 'react';

import { useModalStore } from '../../../stores/modalStore';
import { MODALS } from '../../constants';
import Modal from '../../elements/Modal';
import { Tutorial } from '../../tutorials/dto/tutorials.dto';
import { useTutorials } from '../../tutorials/hooks';
import { LinkedTutorial, Product } from '../dto/product.dto';



type AddLinkedTutorialModalProps = {
  product: Product;
  linkedTutorials: LinkedTutorial[]
  addLinkedTutorial: (tutorial: Tutorial) => void;
  editing: boolean;
};

const AddLinkedTutorialModal = ({ linkedTutorials = [], addLinkedTutorial }: AddLinkedTutorialModalProps) => {
  const modalStore = useModalStore();
  const { data: tutorials } = useTutorials();

  const [selectedTutorials, setSelectedTutorials] = useState<Tutorial[]>([]);

  const close = () => {
    modalStore.closeModal(MODALS.ADD_LINKED_TUTORIAL);
  };

  const tutorialsToAdd = useMemo(() => {
    return tutorials?.filter(tutorial => {
      return !linkedTutorials.some(linkedTutorial => linkedTutorial.id === tutorial.id)
    })
  }, [linkedTutorials, tutorials])

  const toggleTutorial = (tutorial: Tutorial) => {
    if (selectedTutorials.some(selectedTutorial => selectedTutorial.id === tutorial.id)) {
      setSelectedTutorials(selectedTutorials.filter(selectedTutorial => selectedTutorial.id !== tutorial.id))
      return
    }

    setSelectedTutorials([...selectedTutorials, tutorial])
  }

  const addTutorials = () => {
    selectedTutorials.forEach(tutorial => {
      addLinkedTutorial(tutorial)
    });
    setSelectedTutorials([]);
    close();
  }

  return (
    <Modal id={MODALS.ADD_LINKED_TUTORIAL}>
      <>
        <div className="relative pt-4">
          <h1 className="ml-8 text-text text-2xl font-bold">Selecteer tutorials om toe te voegen</h1>
          <X
            className="absolute right-0 top-0 text-text cursor-pointer"
            onClick={close}
          />
        </div>

        <div className="p-8 pb-0 w-full">
          {
            tutorialsToAdd?.length === 0 && (
              <div className="p-4 bg-primary">
                <p>Er zijn geen tutorials meer om toe te voegen.</p>
              </div>
            )
          }

          <ul className="grid grid-cols-2 gap-4 rounded-lg">
            {

              tutorialsToAdd?.map(tutorial => {
                const isToggled = selectedTutorials.some(selectedTutorial => selectedTutorial.id === tutorial.id);

                return (
                  <li
                    className={`p-4 bg-primary relative border-[2px] cursor-pointer border-solid mb-2 rounded-lg  ${isToggled ? 'border-accent' : 'border-[#fff]'}`}
                    key={tutorial.id}
                    onClick={() => toggleTutorial(tutorial)}
                  >
                    <div className="flex flex-col mb-4">
                      <span className="text-xl block font-semibold">{tutorial.name}</span>
                      <span>{tutorial.description}</span>
                    </div>
                  </li>
                )
              })
            }
          </ul>

          <button
            type="submit"
            className={`bg-accent text-primary p-4 w-full rounded-lg mt-4 hover:bg-accent-hover duration-300 transition-all ${selectedTutorials.length === 0 ? 'opacity-50 cursor-not-allowed' : ''}`}
            disabled={selectedTutorials.length === 0}
            onClick={addTutorials}
          >
            Voeg tutorials toe
          </button>

          <button
            className="text-text text-center w-full mt-4 hover:text-accent"
            onClick={close}
          >
            Cancel
          </button>
        </div>
      </>
    </Modal>
  );
};

export default AddLinkedTutorialModal;
