import { z } from 'zod';

export const paginatedSchema = z.object({
  pagination: z.object({
    totalItems: z.number(),
    totalPages: z.number(),
    currentPage: z.number(),
    itemsPerPage: z.number(),
  }),
});

export type PaginatedDto<T> = z.infer<typeof paginatedSchema> & { items: T[] };
