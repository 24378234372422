import { AlertTriangle } from 'lucide-react';

type ErrorProps = {
  error: string
}

const Error = ({ error }: ErrorProps) => {
  return (
    <div className="bg-error-bg text-error p-4 rounded-lg mb-4 flex gap-4 border border-dotted border-error">
      <AlertTriangle />
      <p className="text-error">
        {error}
      </p>
    </div>
  )
}

export default Error;
