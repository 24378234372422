import { useQuery } from '@tanstack/react-query';

import { QUERY_KEYS } from '../constants';
import { doFetch } from '../utils';

import { dashboardStatsSchema } from './dto/dashboard-stats.dto';

const URLS = {
  GET_DASHBOARD_STATS: '/api/v1/dashboard/stats',
};

export const useDashboardStats = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.dashboard_stats],
    queryFn: async () => {
      const { status, data } = await doFetch(URLS.GET_DASHBOARD_STATS, 'GET');

      if (status !== 200) {
        throw new Error();
      }

      return dashboardStatsSchema.parse(data);
    },
  });
};
